import Sidebar from '../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../common/component/topbar/Topbar';
import TabSearchBar from './TabSearchBar';
import { commonStyle } from '../../assets/css/css';
import { connect } from 'react-redux';
// import DataCard from '../component/DataCard';
import DataTable from '../../common/component/table/DataTable';
import Paginator from '../../common/component/pagination/PaginationComponent';
import { GET } from '../../services/HttpRequests';
import Online from '../../assets/images/smallCircleGreen.png';
import Offline from '../../assets/images/smallCircleYellow.png';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PreLoader from '../../common/component/preLoader/PreLoader';
import { lightBlue } from '@material-ui/core/colors';
// import ReportSearchBar from '../component/ReportSearchBar';
import activeStatus from '../../assets/images/activeStatus.svg';

import tea from '../../assets/images/tea.svg';
import lunch from '../../assets/images/lunch.svg';
import meeting from '../../assets/images/meeting.svg';
import training from '../../assets/images/training.svg';
import biobreak from '../../assets/images/biobreak.svg';
import offline from '../../assets/images/offline.svg';
import away from '../../assets/images/away.svg';
import busy from '../../assets/images/busy.svg';
import { Button, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Help from '../../assets/images/help.svg';
import { setIsOpen, setCallId } from '../../actions/adminActions';

const cloudCallCenterUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;


const LiveCallsTable = (props) => {
    const { setIsOpen, setCallId } = props;
    return (
        <div style={{ overflowX: 'auto' }}>
          <DataTable.Table style={{ minWidth: '100%' }}>
            <DataTable.Head>
              <DataTable.Row>
                <DataTable.CellHead>#</DataTable.CellHead>
                <DataTable.CellHead>Destination Number</DataTable.CellHead>
                <DataTable.CellHead>Action</DataTable.CellHead>
              </DataTable.Row>
            </DataTable.Head>
    
            <DataTable.Body>
              {props?.liveCalls?.length > 0 &&
                props?.liveCalls?.map((liveCall, index) => (
                  <DataTable.Row id={liveCall._id.toString()} key={index}>
                    <DataTable.Cell>{1 + index}</DataTable.Cell>
                    <DataTable.Cell>{liveCall.destinationNumber}</DataTable.Cell>
                    <DataTable.Cell>
                      <label onClick= {() => {
                        setIsOpen({isOpen: true})
                        setCallId({callId: liveCall._id.toString()})
                        
                      }} style={{cursor: "pointer", textDecoration: "underline", color: "blue"}}>Listen In</label>
                    </DataTable.Cell>
                  </DataTable.Row>
                ))}
            </DataTable.Body>
          </DataTable.Table>
        </div>
      );
}
export function LiveCalls(props) {
    const {setIsOpen, setCallId} = props
    const [isLoading, setIsLoading] = useState(false);
    const [liveCalls, setLiveCalls] = useState([]);
  const tabs = [];
  tabs.push({
    title: `Agent \n Analytics`,
    onClick: () => props.history.push('/analytics'),
    active: false,
  });

  tabs.push({
    title: `Live \n Calls`,
    onClick: () => props.history.push('/live-calls'),
    active: true,
  });

  const fetchLiveCalls = () => {
    setIsLoading(true);
    const data = {}
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    GET(cloudCallCenterUrl + 'campaigns/live-calls', data)
      .then((res) => {
        
        console.log("++++++++++++", res.data.response)
        setLiveCalls(res.data.response);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }
  useEffect(() => {
    fetchLiveCalls()
  }, []);

  return (
    <div className={`${props.className}`}>
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
        <div className="ContentContainer">
          <TabSearchBar
            tabs={tabs}
            
          />
          {isLoading && <PreLoader />}
          {!isLoading && (
           <LiveCallsTable liveCalls={liveCalls} setIsOpen= {setIsOpen} setCallId={setCallId}></LiveCallsTable>
          )}
          {/* <Paginator
            currentPage={1}
            lastPage={1}
          /> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {setIsOpen, setCallId})(LiveCalls));
