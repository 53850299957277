import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { DELETE, GET, PUT } from '../../../services/HttpRequests';
import DeleteAdminPopup from '../component/DeleteAdminPopup';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import SearchBar from '../../../common/component/searchbar/SearchBarComponent';
import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import DataTable from '../../../common/component/table/DataTable';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import { commonStyle } from '../../../assets/css/css';
import EditAdminPopup from '../component/EditAdminPopup';

const userUrl = process.env.REACT_APP_USER_API;

class AdminsPage extends Component {
  constructor() {
    super();
    this.state = {
      usersList: [],
      adminsList: [],
      count: 0,
      adminsCount: 0,
      totalPages: 0,
      pageSize: 15,
      currentPage: 1,
      editUserError: '',
      loading: false,
      searchData: {},
      managersData: [],
    };
  }

  componentDidMount() {
    this.getFreshList();
    this.getUsersList();
  }

  getFreshList = () => {
    this.setState({ loading: true });
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    let data = {
      pageNo: this.state.currentPage,
      pageSize: this.state.pageSize,
      organisationId: orgId,
    };
    GET(userUrl + `v1/admin`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          adminsList: res.adminObjs,
          adminsCount: res.totalCount,
          totalPages: Math.ceil(res.totalCount / this.state.pageSize),
          pageSize: res.pageSize,
          loading: false,
          searchData: {},
        });
      })
      .catch((err) => {
        if (err) {
          this.setState({ loading: false });
        }
      });
  };

  getUsersList = () => {
    this.setState({ loading: true });
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    let data = {
      pageNo: 1,
      pageSize: 20,
      organisationId: orgId,
    };
    GET(userUrl + `v1/user`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          count: res.users.count,
          loading: false,
        });
      })
      .catch((err) => {
        if (err) {
          this.setState({ loading: false });
        }
      });
  };

  searchHandler = (data) => {
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    const searchData = {
      searchKey: data.searchKey,
      searchValue: data.searchCondition,
      pageNo: 1,
      pageSize: 20,
      organisationId: orgId
    };
    GET(userUrl + `v1/admin`, searchData)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          adminsList: res.adminObjs,
          adminsCount: res.totalCount,
          totalPages: Math.ceil(res.totalCount / this.state.pageSize),
          pageSize: res.pageSize,
          loading: false,
          searchData: {},
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchPageData = (value) => {
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    let data = {
      pageNo: value,
      pageSize: this.state.pageSize,
      organisationId: orgId,
    };
    data = { ...data, ...this.state.searchData };
    GET(userUrl + `v1/admin`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.setState({
          adminsList: res.adminObjs,
          adminsCount: res.totalCount,
          totalPages: Math.ceil(res.totalCount / this.state.pageSize),
          pageSize: res.pageSize,
          loading: false,
          searchData: {},
          currentPage: value
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  editUserDetailsHandler = async (userId, data, callback) => {
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    await PUT(
      `${userUrl}v1/admin`,
      {},
      { ...data, userId: userId, organisationId: orgId }
    )
      .then((res) => {
        this.getFreshList();
        callback();
      })
      .catch((err) => {
        this.setState({ editUserError: err.response.data.error.reason });
        callback();
      });
  };

  deleteUserHandler = (userId) => {
    let orgId = localStorage.getItem('doosra-biz-organisation-id');
    DELETE(`${userUrl}v1/admin`, { organisationId: orgId, userId: userId })
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        toast.success('Deleted Successfully!');
        this.getFreshList();
      })
      .catch((err) => {
        toast.error(
          `${err?.response?.data?.error?.reason}` || 'An error occured'
        );
      });
  };

  trimNumber = (number) => {
    if (number && number.length == 12) return number.substring(2);
    return number;
  };

  isLocked = (item) =>
    (item.organisation && item.organisation.has_locked) || item?.has_locked;

  render() {
    let displayData;
    if (this.state.loading) {
      displayData = <PreLoader />;
    } else {
      if (this.state.adminsList.length > 0) {
        displayData = this.state.adminsList.map((admin) => (
          <DataTable.Row key={admin.user_id}>
            <DataTable.Cell style={{ padding: 5 }}>
              {moment(admin.created_at).format('DD/MM/YYYY')}
            </DataTable.Cell>
            <DataTable.Cell style={{ padding: 5 }}>
              {admin.name}
            </DataTable.Cell>
            <DataTable.Cell style={{ padding: 5 }}>
              {admin.phone_number}
            </DataTable.Cell>
            <DataTable.Cell style={{ padding: 10 }}>
              {admin.email}
            </DataTable.Cell>
            <DataTable.Cell style={{ padding: 5 }}>
              {admin.is_manager ? 'MANAGER' : 'ADMIN'}
            </DataTable.Cell>
            <DataTable.Cell style={{ padding: 0, paddingRight: 3 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {isSuperAdminWithPermission('EDIT') && (
                  <EditAdminPopup
                    key={admin.user_id}
                    userId={admin.user_id}
                    email={admin.email}
                    name={admin.name}
                    accessRole={admin.is_manager ? 'manager' : 'admin'}
                    editUserError={this.state.editUserError}
                    number={admin.phone_number}
                    editUser={(id, data, callback) =>
                      this.editUserDetailsHandler(id, data, callback)
                    }
                  />
                )}
                {isSuperAdminWithPermission('DELETE') && (
                  <DeleteAdminPopup
                    key={admin.user_id + admin.name}
                    userId={admin.user_id}
                    name={admin.name}
                    number={admin.phone_number}
                    deleteUser={(id) => this.deleteUserHandler(id)}
                  />
                )}
              </div>
            </DataTable.Cell>
          </DataTable.Row>
        ));
      }
    }
    return (
      <div className={`${this.props.className}`}>
        <Topbar />
        <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
          <Sidebar />
          <div className="ContentContainer">
            <SearchBar
              type="admins"
              count={this.state.count}
              adminsCount={this.state.adminsCount}
              search={this.searchHandler}
              reset={this.getFreshList}
              refreshList={this.getFreshList}
              history={this.props.history}
            />
            <DataTable.Table>
              <DataTable.Head>
                <DataTable.CellHead style={{ width: '10%' }}>
                  ADDED ON
                </DataTable.CellHead>
                <DataTable.CellHead style={{ width: '20%' }}>
                  NAME
                </DataTable.CellHead>
                <DataTable.CellHead style={{ width: '12%' }}>
                  PERSONAL NO
                </DataTable.CellHead>
                <DataTable.CellHead style={{ width: '12%' }}>
                  EMAIL
                </DataTable.CellHead>
                <DataTable.CellHead style={{ width: '12%' }}>
                  ACCESS ROLE
                </DataTable.CellHead>
                <DataTable.CellHead style={{ width: '20%' }}>
                  ACTIONS
                </DataTable.CellHead>
              </DataTable.Head>
              <DataTable.Body>{displayData}</DataTable.Body>
            </DataTable.Table>
            <Paginator
              currentPage={this.state.currentPage}
              lastPage={this.state.totalPages}
              getInfo={(value) => this.fetchPageData(value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  callForwardingStatus: state.topbar.call_forwarding_status,
  isOrgOutboundCallEnabled: state.topbar.isOrgOutboundCallEnabled,
  isWebrtcEnabled: state.topbar.isWebrtcEnabled,
  isCCWebrtcEnabled: state.topbar.isCCWebrtcEnabled,
  isIncomingWebrtcEnabled: state.topbar.isIncomingWebrtcEnabled,
});

export default commonStyle(connect(mapStateToProps, {})(AdminsPage));
