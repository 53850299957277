import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import UserRemove from '../../../assets/images/userMinusWhite.png';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import { ADMIN_UNASSIGN_NUMBER_BUTTON } from "../../../common/posthog/events";
import { logPostHogEvent } from "../../../common/posthog/posthog";
import './Tooltip.css';

class UnassignConfirmationPopup extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      isHovered: false,
    };
  }

  openPopup = () => {
    logPostHogEvent(ADMIN_UNASSIGN_NUMBER_BUTTON);
    if (!this.props.nonIncomingDid) {
      this.setState({ popupOpen: true });
    }
  };
  closePopup = () => {
    this.setState({ popupOpen: false });
  };

  unassignUserAndClosePopup = () => {
    if (this?.props?.numberDetails?.user_id) {
      this.props.unassignUser();
    }
    if (
      this?.props?.numberDetails?.routing_group_id ||
      this?.props?.numberDetails?.routingGroupId
    ) {
      this.props.unassignGroup();
    }
    if (this?.props?.numberDetails?.autoAttendant?.length > 0) {
      this.props.unassignAutoAttendant();
    }
    this.closePopup();
  };

  render() {
    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Unassign?</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={this.closePopup}
            />
          </div>
          <div className="PopupBody">
            {this.props?.numberDetails?.members?.length &&
              this.props?.numberDetails?.user_id ? (
              <>
                <p className="PopupBodyTitle" style={{ padding: '0 50px' }}>
                  {`The agent associated with this number is part of a call routing group(s). Do you still want to proceed with unassigning this number?`}
                </p>
                <p className="PopupNote" style={{ padding: '0 50px' }}>
                  {`If unassigned, the associated agent shall be removed from the routing group(s) and this might affect the call routing flow.`}
                </p>
              </>
            ) : (
              <>
                <p className="PopupBodyTitle" style={{ padding: '0 50px' }}>
                  {`Are you sure you want to unassign the ${this.props.numberDetails.routing_group_id ||
                    this.props.numberDetails?.routingGroupId
                    ? 'group'
                    : 'agent'
                    } from this number?`}
                </p>
                <p className="PopupNote" style={{ padding: '0 50px' }}>
                  {this.props.groupType == 'OUTGOINGCALL'
                    ? `If unassigned, the associated DID shall be removed from the routing group.`
                    : `You can still receive and view OTPs, SMSs from this number in the dashboard even after it has been unassigned.`}
                </p>
              </>
            )}
            <button
              className="ButtonFullWidth BgYellow"
              onClick={this.unassignUserAndClosePopup}
            >
              UNASSIGN
            </button>
          </div>
        </div>
      </Popup>
    );
    const isIvrNumber = this?.props?.numberDetails?.ivr_id || false;

    return (
      <span>
        {/* {!this?.props?.numberDetails?.ivr_id && */}
        <div className="tooltip-container">
          <div className="tooltip-button">
          <button
              style={{ width: "2rem" }} onClick={this.openPopup} className={this.props.nonIncomingDid || isIvrNumber || !isSuperAdminWithPermission('EDIT') ? "DisableButton" : "UnassignButton"} disabled={isIvrNumber || !isSuperAdminWithPermission('EDIT')}
              onMouseEnter={() => this.setState({  isHovered: true  })}
              onMouseLeave={() => this.setState({  isHovered: false  })}
            >
              <img alt="" src={UserRemove} style={{ paddingRight: '10px' }} />
              {/* UN-ASSIGN */}
            </button>
          </div>
          {this.state.isHovered && (
              <span style={{  position: "absolute", top: "100%", left: "50%", marginTop: "5px", backgroundColor: "#1f2937", minWidth: "100px", color: "white" }}>
                UN-ASSIGN
              </span>
            )}
          {this.props.nonIncomingDid && <div className="tooltip-text left" data-tip-position="left">
            For outgoing calls only
            < span className="arrow-right"></span>
          </div>}
        </div>
        {/* } */}
        {popper}
      </span>
    );
  }
}

export default UnassignConfirmationPopup;
