const initialState = {
  isOpen: false,
  isMicOff: false,
  isPaused: false,
  isCallInProgress: false,
  numbers: [],
  destinationNumber: '',
  destinationName: "",
  did: '',
  callType: '',
  callStatus: 'Ringing',
  UAList: [],
  RTCSessionData: null,
  dispositions: [],
  disposition: "",
  timer: 0,
  runTimer: false,
  callId: '',
  showDailNextButton: false,
  dailNextTimer: 30,
  agentStatus:
    (localStorage.getItem('agentStatus') === 'undefined' ||
      localStorage.getItem('agentStatus') === undefined ||
      localStorage.getItem('agentStatus') === null ||
      localStorage.getItem('agentStatus') === 'null')
      ? 'Online' : localStorage.getItem('agentStatus'),
  contactId: '',
  selectedDailer: "solo",
  agentActiveCampaigns: [],
  selectedCampaign: "",
  selectedCampaignDetails: "",
  showCampaignExitScreen: false,
  isCampaignInProgress: false,
  isAgentStatusUpdated: false,
  batchId: "",
  campaignCompletionStat: "",
  campaignId: '',

  showCallMergeAndTransferUI: '',
  dontAllowMergeAndTransfer: '',
  unfilteredAgentsList: [],
  agentsList: [],
  activeCallAgents: [],
  onGoingOtherAgent: {},
  onGoingOtherAgentTimerStatus: '',
  ongoingCallInterval: null,
  activeAgentIntervals: {},
  showCustomerDetails: false,
  leadDetails: {},
  predictiveCampaignCallStats: {},
  isIncomingCall: false,
  incomingCallPopup: '',
  incomingWay: '',
  incomingRingSoundToggle: false,
  checkIncomingKey: false,
  isManualUpdate: true,
  allowCallHangup: false,
  isAgentConnected: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_IS_OPEN':
      return {
        ...state,
        isOpen: action.payload.isOpen,
      };
    case 'SET_CAMPAIGN_ID':
      return {
        ...state,
        campaignId: action.payload.campaignId,
      };
    case 'SET_IS_MIC_OFF':
      return {
        ...state,
        isMicOff: action.payload.isMicOff,
      };
    case 'SET_IS_PAUSED':
      return {
        ...state,
        isPaused: action.payload.isPaused,
      };
    case "SET_SELECTED_DAILER":
      return {
        ...state,
        selectedDailer: action.payload.selectedDailer,
      }
    case "SET_AGENT_ACTIVE_CAMPAIGNS":
      return {
        ...state,
        agentActiveCampaigns: action.payload.agentActiveCampaigns,
      }
    case "SET_SELECTED_CAMPAIGN":
      return {
        ...state,
        selectedCampaign: action.payload.selectedCampaign,
      }
    case "SET_SELECTED_CAMPAIGN_DETAILS":
      return {
        ...state,
        selectedCampaignDetails: action.payload.selectedCampaignDetails
      }
    case "SET_IS_AGENT_STATUS_UPDATED":
      return {
        ...state,
        isAgentStatusUpdated: action.payload.isAgentStatusUpdated,
      }
    case "SET_SHOW_CAMPAIGN_EXIT_SCREEN":
      return {
        ...state,
        showCampaignExitScreen: action.payload.showCampaignExitScreen
      }
    case 'SET_IS_CALL_IN_PROGRESS':
      return {
        ...state,
        isCallInProgress: action.payload.isCallInProgress,
      };
    case "IS_CAMPAIGN_IN_PROGRESS":
      return {
        ...state,
        isCampaignInProgress: action.payload.isCampaignInProgress,
      }
    case "SET_BATCH_ID":
      return {
        ...state,
        batchId: action.payload.batchId,
      }
    case "SET_CAMPAIGN_COMPLETION_STAT":
      return {
        ...state,
        campaignCompletionStat: action.payload.campaignCompletionStat
      }
    case 'SET_NUMBERS':
      return {
        ...state,
        numbers: action.payload.numbers,
      };
    case 'SET_DESTINATION_NUMBER':
      return {
        ...state,
        destinationNumber: action.payload.destinationNumber,
      };
    case 'SET_DESTINATION_NAME':
      return {
        ...state,
        destinationName: action.payload.destinationName,
      }
    case 'SET_DID':
      return {
        ...state,
        did: action.payload.did,
      };
    case 'SET_CALL_TYPE':
      return {
        ...state,
        callType: action.payload.callType,
      };
    case 'SET_CALL_STATUS':
      return {
        ...state,
        callStatus: action.payload.callStatus,
      };
    case 'SET_UA_LIST':
      return {
        ...state,
        UAList: action.payload.UAList,
      };
    case 'SET_RTC_SESSION_DATA':
      return {
        ...state,
        RTCSessionData: action.payload.RTCSessionData,
      };
    case 'SET_DISPOSITIONS':
      return {
        ...state,
        dispositions: action.payload.dispositions,
      };
    case 'SET_DISPOSITION':
      return {
        ...state,
        disposition: action.payload.disposition,
      }
    case 'SET_TIMER':
      return {
        ...state,
        timer:
          action.payload.timer || action.payload.timer === 0
            ? action.payload.timer
            : state.timer + 1,
      };
    case 'SET_RUN_TIMER':
      return {
        ...state,
        runTimer: action.payload.runTimer,
      };
    case 'SET_CALL_ID':
      return {
        ...state,
        callId: action.payload.callId,
      };
    case 'SET_SHOW_DAIL_NEXT_BUTTON':
      return {
        ...state,
        showDailNextButton: action.payload.showDailNextButton,
      };
    case 'SET_DAIL_NEXT_TIMER':
      return {
        ...state,
        dailNextTimer: action.payload.dailNextTimer
          ? action.payload.dailNextTimer
          : state.dailNextTimer - 1,
      };
    case 'SET_AGENT_STATUS':
      return {
        ...state,
        agentStatus: action.payload.agentStatus,
      };
    case 'SET_CONTACT_ID':
      return {
        ...state,
        contactId: action.payload.contactId,
      };
    case 'SET_SHOW_CALL_MERGE_AND_TRANSFER_UI':
      return {
        ...state,
        showCallMergeAndTransferUI: action.payload.showCallMergeAndTransferUI,
      };
    case 'SET_UNFILTERED_AGENTS_LIST':
      return {
        ...state,
        unfilteredAgentsList: action.payload.unfilteredAgentsList,
      };
    case 'SET_AGENTS_LIST':
      return {
        ...state,
        agentsList: action.payload.agentsList,
      };
    case 'SET_ACTIVE_CALL_AGENTS':
      return {
        ...state,
        activeCallAgents: action.payload.activeCallAgents,
      };
    case 'INCREASE_TIMER_ACTIVE_CALL_AGENT':
      const { agentId } = action.payload;
      const activeCallAgents = state.activeCallAgents.map(a =>
        a.agentId === agentId ? { ...a, timer: a.timer + 1 } : a
      );
      return {
        ...state,
        activeCallAgents,
      };
    case 'SET_ONGOING_OTHER_AGENT':
      return {
        ...state,
        onGoingOtherAgent: action.payload.onGoingOtherAgent,
      };

    case 'UPDATE_ONGOING_OTHER_AGENT':
      const { incrementTimer, callStatus, timer } = action.payload;
      const addOnPayload = {};
      if (callStatus) {
        addOnPayload.callStatus = callStatus;
        addOnPayload.status = callStatus;
      }
      if (timer || timer === 0) {
        addOnPayload.timer = timer;
      }
      if (incrementTimer) {
        addOnPayload.timer = state.onGoingOtherAgent.timer + (incrementTimer ? 1 : 0);
      }
      console.log("reducer:", {
        ...state.onGoingOtherAgent,
        ...addOnPayload,
      });
      return {
        ...state,
        onGoingOtherAgent: {
          ...state.onGoingOtherAgent,
          ...addOnPayload,
        },
      };

    case 'SET_ONGOING_OTHER_AGENT_TIMER_STATUS':
      return {
        ...state,
        onGoingOtherAgentTimerStatus: action.payload.onGoingOtherAgentTimerStatus,
      };
    case 'SET_ONGOING_CALL_INTERVAL':
      return {
        ...state,
        ongoingCallInterval: action.payload.ongoingCallInterval,
      };
    case 'SET_ACTIVE_AGENT_INTERVALS':
      return {
        ...state,
        activeAgentIntervals: action.payload.activeAgentIntervals,
      };
    case 'SET_DONT_ALLOW_MERGE_AND_TRANSFER':
      return {
        ...state,
        dontAllowMergeAndTransfer: action.payload.dontAllowMergeAndTransfer,
      };
    case 'SET_SHOW_CUSTOMER_DETAILS':
      return {
        ...state,
        showCustomerDetails: action.payload.showCustomerDetails
      }
    case "SET_LEAD_DETAILS":
      return {
        ...state,
        leadDetails: action.payload.leadDetails
      }
    case 'SET_IS_INCOMING_CALL':
      return {
        ...state,
        isIncomingCall: action.payload.isIncomingCall,
      };
    case 'SET_INCOMING_CALL_POPUP':
      return {
        ...state,
        incomingCallPopup: action.payload.incomingCallPopup,
      };
    case 'SET_INCOMING_WAY':
      return {
        ...state,
        incomingWay: action.payload.incomingWay,
      };
    case 'SET_INCOMING_RING_SOUND_TOGGLE':
      return {
        ...state,
        incomingRingSoundToggle: action.payload.incomingRingSoundToggle,
      };
    case "SET_PREDICTIVE_CAMPAIGN_CALL_STATS":
      return {
        ...state,
        predictiveCampaignCallStats: action.payload.predictiveCampaignCallStats
      }
    case 'SET_CHECK_INCOMING_KEY':
      return {
        ...state,
        checkIncomingKey: action.payload.checkIncomingKey,
      };
    case "SET_IS_MANUAL_UPDATE":
      return {
        ...state,
        isManualUpdate: action.payload.isManualUpdate
      }
    case "SET_ALLOW_CALL_HANGUP":
      return {
        ...state,
        allowCallHangup: action.payload.allowCallHangup
      }
    case "IS_AGENT_CONNECTED":
      return {
        ...state,
        isAgentConnected: action.payload.isAgentConnected
      }
    default:
      return state;
  }
}