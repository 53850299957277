import React, { useState, useEffect } from 'react';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import { alpha, styled } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { PUT } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';

const orgUrl = process.env.REACT_APP_ORGANIZATION_API;

const CallAutoAnalysisPage = (props) => {
  const [callAutoAnalysisToggle, setCallAutoAnalysis] = useState(props.isCallAutoAnalysis);
  const [durationLimit, setDurationLimit] = useState(props.isCallAutoAnalysisDurationLimit);
  const [sendEmailForSentimentType, setSendEmailForSentimentType] = useState(props.isCallAutoAnalysisSentimentType);

  const updateCallAutoAnalysisData = async (key, value) => {
    try {
      const body = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      };
      body[key] = value;
      await PUT(`${orgUrl}v1/organisation/update-call-auto-analysis`, {}, body);
      toast.success("Updated Successfully!!!");
    } catch (e) {
      toast.error("updation failed!!!");
    }
  }

  const handleCallAutoAnalysis = async () => {
    await updateCallAutoAnalysisData("callAutoAnalysis", !callAutoAnalysisToggle);
    setCallAutoAnalysis((prevState) => {
      const newState = !prevState;
      return newState;
    });
  };

  const handleDurationLimit = async (value) => {
    await updateCallAutoAnalysisData("durationLimit", parseInt(value));
    setDurationLimit(value);
  };

  const handleSendEmailForSentimentType = async (value) => {
    await updateCallAutoAnalysisData("sendEmailForSentimentType", value);
    setSendEmailForSentimentType(value);
  }

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: green[800],
      '&:hover': {
        backgroundColor: alpha(green[800], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: green[800],
    },
  }));
  return (
    <div className={`${props.className}`}>
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
        <div className="ContentContainer">
          <div
            style={{
              width: '40vw',
              height: 'auto',
              border: '1px solid #d2d3d4',
              boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.16)',
              marginTop: '40px',
              marginLeft: '20px',
              padding: '10px',
            }}
          >
            <h2
              style={{
                fontSize: '18px',
                fontFamily: 'Montserrat',
                fontWeight: 'bold',
                marginLeft: '20px',
                marginTop: '20px',
              }}
            >
              Call Auto-Analysis &nbsp;<GreenSwitch onChange={handleCallAutoAnalysis} checked={callAutoAnalysisToggle} />
            </h2>
            <div
              style={{
                fontSize: '14px',
                color: '#666',
                fontFamily: 'Montserrat',
                fontWeight: 'normal',
                fontStretch: 'normal',
                lineHeight: '1.5',
                letterSpacing: '0.5px',
                marginLeft: '20px',
              }}
            >
              Automatically analyze every call, or only the ones that matter.
            </div>
            <div
              style={{
                alignItems: 'center',
                marginTop: '20px',
                marginLeft: '20px',
                marginRight: '20px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: "center",
                  width: '530px'
                }}
              >
                <div style={{ width: '420px', fontWeight: 600 }}>Analyze calls longer than:</div>
                <select
                  className="InputBox"
                  disabled={!callAutoAnalysisToggle}
                  value={durationLimit}
                  onChange={(e) => handleDurationLimit(e.target.value)}
                >
                  {[10, 20, 30].map(i =>
                    <option value={i}>
                      {i}
                    </option>)}
                </select>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: "center",
                  width: '530px'
                }}
              >
                <div style={{ width: '420px', fontWeight: 600 }}>Send email for:</div>
                <select
                  className="InputBox"
                  disabled={!callAutoAnalysisToggle}
                  value={sendEmailForSentimentType}
                  onChange={(e) => handleSendEmailForSentimentType(e.target.value)}
                >
                  {["All", "Positive", "Neutral", "Negative"].map(i => <option value={i.toLowerCase()}>
                    {i}
                  </option>)}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  isCallAutoAnalysis: state.topbar.isCallAutoAnalysis,
  isCallAutoAnalysisDurationLimit: state.topbar.isCallAutoAnalysisDurationLimit,
  isCallAutoAnalysisSentimentType: state.topbar.isCallAutoAnalysisSentimentType,
});

export default commonStyle(connect(mapStateToProps, {})(CallAutoAnalysisPage));
