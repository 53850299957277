import adapter from 'webrtc-adapter';
import JsSIP from 'jssip';
import { commonStyle } from '../../../assets/css/css';
import { connect, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import './AdminDailPad.css';
import AlohaaLogo from '../../../assets/images/AlohaaLogoWhite.png';
import ExitToAppLogo from '../../../assets/images/exit-to-app.svg';
import { GET, POST } from '../../../services/HttpRequests';
import { useSocket } from '../../../providers/SocketProvider';
import Play from '../../../assets/images/play_arrow.svg';
import Pause from '../../../assets/images/pause.svg';
import GreyPause from '../../../assets/images/GreyPause.svg';
import MicOff from '../../../assets/images/mic_off.svg';
import Mic from '../../../assets/images/mic.svg';
import CallListen from '../../../assets/images/callListen.svg';
import GreyMic from '../../../assets/images/GreyMic.svg';
import HangupLogo from '../../../assets/images/RecoveryHangup.webp';
import CallWhisper from '../../../assets/images/callWhisper.svg';
import CallBarge from '../../../assets/images/CallBarge.svg';
import CallInterpret from '../../../assets/images/users.png';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  setCallParticipents,
  setRunTimer,
  setTimer,
  setCallType,
  setCallAction,
  setUAList,
  setRTCSession,
  setIsPaused,
  setisMicOff,
  setAdminRunTimer,
  setAdminTimer,
  setIsOpen,
  setCallId,
  setIsConnectionEstablished,
} from '../../../actions/adminActions';
import { toast } from 'react-toastify';
import { TURN_SERVER_CONFIG } from '../../../assets/constants';
import { decryptData } from '../../../utility/cryptoUtils';
const cloudCallUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;

const sip_details = JSON.parse(localStorage.getItem('sip_details'));
const username = sip_details?.username || '';
const password = sip_details?.password || '';

const GradientDiv = styled.div`
  height: 98px;
  background: linear-gradient(#f8153b, #920c23);
  display: flex;
  flex-direction: row;
  border-radius: 12px 12px 0px 0px;
`;

const FooterDiv = styled.div`
  height: 72px;
  border-radius: 0px 0px 12px 12px;
  border-top: 1px solid var(--Light-Gray, #d9d9d9);
  background: #fff;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.06);
  position: relative;
  top: 0px;
`;

const CallParticipentsDiv = styled.div`
  height: 247px;
  overflow-y: auto;
`;

const CallPraticipentDiv = styled.div`
  height: 46px;
  position: relative;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.16);
`;

const CallDescription = styled.div`
  width: 300px;
  height: 27px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AdminDailPad = (props) => {
  const ListenFooter = () => {
    return (
      <>
        <div
          className="tooltip-container"
          style={{
            position: 'absolute',
            left: '16px',
            top: '16px',
            cursor: 'pointer',
          }}
          onClick={() => {
            switchToWhisper();
          }}
        >
          <img src={CallListen} width={'40px'} height={'40px'}></img>
          <div className="tooltip-text  top" data-tip-position="top">
            Whisper
          </div>
        </div>

        <div
          className="tooltip-container"
          style={{
            position: 'absolute',
            left: '72px',
            top: '16px',
            cursor: 'pointer',
          }}
          onClick={() => {
            switchToBarge();
          }}
        >
          <img src={CallBarge} width={'40px'} height={'40px'}></img>
          <div className="tooltip-text  top" data-tip-position="top">
            Barge
          </div>
        </div>

        <div className={callType === 'PSTN' ? 'exitCallDisabled' : 'exitCall'}>
          <img onClick={() => endCall()} src={ExitToAppLogo}></img>
        </div>
      </>
    );
  };
  const BargeFooter = () => {
    return (
      <div className="bargeFooter">
        <OverlayTrigger
          placement="left"
          overlay={
            callType === 'PSTN' ? (
              <Tooltip id="tooltip-left">
                Use your phone to manage GSM calls.
              </Tooltip>
            ) : (
              <div></div>
            )
          }
        >
          <div
            style={{
              width: '36px',
              height: '36px',
              borderRadius: '50%',
              background:
                callType === 'PSTN' ? '#D9D9D9' : isMicOff ? 'red' : 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: `1px solid ${callType === 'PSTN' ? '#D9D9D9' : 'red'}`,
              cursor: callType === 'PSTN' ? 'not-allowed' : 'pointer',
            }}
            onClick={() => {
              if (isMicOff) {
                micOn();
              } else {
                micOff();
              }
              setisMicOff({ isMicOff: !isMicOff });
            }}
          >
            <img
              src={callType === 'PSTN' ? GreyMic : isMicOff ? MicOff : Mic}
              width={'15px'}
              height={'15px'}
            ></img>
          </div>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          overlay={
            callType === 'PSTN' ? (
              <Tooltip id="tooltip-left">
                Use your phone to manage GSM calls.
              </Tooltip>
            ) : (
              <div></div>
            )
          }
        >
          <div
            style={{
              width: '36px',
              height: '36px',
              borderRadius: '50%',
              background:
                callType === 'PSTN' ? '#D9D9D9' : isPaused ? 'red' : 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: `1px solid ${callType === 'PSTN' ? '#D9D9D9' : 'red'}`,
              cursor: callType === 'PSTN' ? 'not-allowed' : 'pointer',
            }}
            onClick={() => {
              if (isPaused) {
                unHoldCall();
              } else {
                holdCall();
              }
              setIsPaused({ isPaused: !isPaused });
            }}
          >
            <img
              src={callType === 'PSTN' ? GreyPause : isPaused ? Play : Pause}
              width={'15px'}
              height={'15px'}
            ></img>
          </div>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          overlay={
            callType === 'PSTN' ? (
              <Tooltip id="tooltip-left">
                Use your phone to manage GSM calls.
              </Tooltip>
            ) : (
              <div></div>
            )
          }
        >
          <div
            style={{
              width: '64px',
              height: '36px',
              borderRadius: '22px',
              background: callType === 'PSTN' ? '#D9D9D9' : 'red',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: `1px solid ${callType === 'PSTN' ? '#D9D9D9' : 'red'}`,
              cursor: callType === 'PSTN' ? 'not-allowed' : 'pointer',
            }}
            onClick={() => {
              endCall();
            }}
          >
            <img src={HangupLogo} width={'22px'} height={'9px'}></img>
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  const WhisperFooter = () => {
    return (
      <>
        <div
          className="tooltip-container"
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            background:
              callType === 'PSTN' ? '#D9D9D9' : isMicOff ? 'white' : 'red',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: `1px solid ${callType === 'PSTN' ? '#D9D9D9' : 'red'}`,
            cursor: callType === 'PSTN' ? 'not-allowed' : 'pointer',
            position: 'absolute',
            top: '16px',
            left: '24px',
          }}
          onClick={() => {
            if (isMicOff) {
              micOn();
            } else {
              micOff();
            }
            setisMicOff({ isMicOff: !isMicOff });
          }}
        >
          <img src={CallInterpret} width={'24px'} height={'24px'}></img>
          <div className="tooltip-text  top" data-tip-position="top">
            Whisper
          </div>
        </div>
        <div
          className="tooltip-container"
          style={{
            position: 'absolute',
            left: '76px',
            top: '16px',
            cursor: 'pointer',
          }}
          onClick={() => {
            switchToBargeFromWhisper();
          }}
        >
          <img src={CallBarge} width={'40px'} height={'40px'}></img>
          <div className="tooltip-text  top" data-tip-position="top">
            Barge
          </div>
        </div>
        <div className={callType === 'PSTN' ? 'exitCallDisabled' : 'exitCall'}>
          <img onClick={() => endCall()} src={ExitToAppLogo}></img>
        </div>
      </>
    );
  };
  const {
    setCallParticipents,
    setTimer,
    setRunTimer,
    setCallType,
    setCallAction,
    setUAList,
    setRTCSession,
    setIsPaused,
    setisMicOff,
    setAdminRunTimer,
    setAdminTimer,
    setIsOpen,
    setCallId,
    setIsConnectionEstablished,
  } = props;
  const socket = useSocket();
  const [turnServerConfigs, setTurnServerConfigs] = useState({});
  let options = {
    mediaConstraints: { audio: true, video: false },
    pcConfig: {},
  };

  const switchToBargeFromWhisper = () => {
    const data = {
      callId,
      dialer: 'solo',
    };
    POST(cloudCallUrl + 'cloud-call/switch-to-barge', data)
      .then((res) => {
        setCallAction({ callAction: 'barge' });
        setCallType({ callType: 'VOIP' });
        if (rtcSession) {
          rtcSession.unmute();
        }
        setisMicOff({ isMicOff: false });
      })
      .catch((err) => console.log(err));
  };

  const switchToWhisper = () => {
    var data = JSON.stringify({
      callId,
      dialer: 'solo',
    });
    POST(cloudCallUrl + 'cloud-call/whisper', data)
      .then((res) => {
        setCallAction({ callAction: 'whisper' });
        setCallType({ callType: 'VOIP' });
        micOff();
        setisMicOff({ isMicOff: true });
        setCallParticipents({
          callParticipents: [
            ...callParticipents,
            {
              name: localStorage.getItem('doosra-biz-user-name'),
              role: 'Admin',
              status: 'Whispering',
            },
          ],
        });
      })
      .catch((err) => console.log(err));
  };

  const endCall = () => {
    console.log('end call');
    if (rtcSession) {
      console.log('terminating the call');
      rtcSession.terminate();
    }
  };

  const micOn = () => {
    if (rtcSession) {
      rtcSession.unmute();
    }
  };

  const micOff = () => {
    if (rtcSession) {
      rtcSession.mute();
    }
  };

  const holdCall = () => {
    if (rtcSession) {
      rtcSession.hold();
    }
  };

  const unHoldCall = () => {
    if (rtcSession) {
      rtcSession.unhold();
    }
  };

  const switchToBarge = () => {
    var data = JSON.stringify({
      callId,
      dialer: 'solo',
    });
    POST(cloudCallUrl + 'cloud-call/barge', data)
      .then((res) => {
        setCallAction({ callAction: 'barge' });
        setCallType({ callType: 'VOIP' });
        setCallParticipents({
          callParticipents: [
            ...callParticipents,
            {
              name: localStorage.getItem('doosra-biz-user-name'),
              role: 'Admin',
              status: 'Barged',
            },
          ],
        });
      })
      .catch((err) => console.log(err));

    setCallAction({ callAction: 'barge' });
  };

  const handleWebsocketConnection = async (servers) => {
    const uaList = servers.map((server) => {
      const socket = new JsSIP.WebSocketInterface(`wss://${server}/ws`);
      const uaConfig = {
        uri: `sip:${username}@${server}`,
        password: password,
        sockets: [socket],
        connection_recovery_min_interval: 5,
      };
      const ua = new JsSIP.UA(uaConfig);
      ua.start();
      return { ua: ua, socket };
    });
    console.log(uaList);
    setUAList({ UAList: uaList });
    return uaList;
  };

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    let formattedTime;
    if (hours > 0) {
      formattedTime = `${hours.toString().padStart(2, '0')}: ${minutes
        .toString()
        .padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    } else {
      formattedTime = `${minutes
        .toString()
        .padStart(2, '0')}: ${remainingSeconds.toString().padStart(2, '0')}`;
    }
    return formattedTime;
  }
  function getCallTypeDescClass() {
    switch (callAction) {
      case 'listen':
        return 'callTypeListenDesc';
      default:
        return 'callTypeBargeDesc';
    }
  }
  function getCallActionText() {
    switch (callAction) {
      case 'listen':
        return 'You are listening in and are muted to others.';
      case 'barge':
        return "You're on this call and others can hear you.";
      case 'whisper':
        const filteredParticipentsArray = callParticipents.filter((obj) => obj.role === "Agent");
        const filteredObj = filteredParticipentsArray.length ? filteredParticipentsArray[0] : {};
        const name = filteredObj?.name || "Agent"
        return `You are whispering to ${name}.`;
    }
  }
  function getCallTime(startTime) {
    const now = new Date();
    const start = new Date(startTime);

    const difference = now - start || 0;

    console.log('--------', now, start, difference);

    return parseInt(difference / 1000, 10);
  }
  const makeCall = async () => {
    await getTurnConfiguration();
    const server = [domain];
    handleWebsocketConnection(server)
      .then((res) => { console.log("Connected") })
      .catch((err) => {
        toast.error('Connection Failed');
        setIsOpen({ isOpen: false });
      });
  };

  const callParticipents = useSelector(
    (state) => state.adminData.callParticipents
  );
  const adminTimer = useSelector((state) => state.adminData.adminTimer);
  const adminRunTimer = useSelector((state) => state.adminData.adminRunTimer);
  const timer = useSelector((state) => state.adminData.timer);
  const runTimer = useSelector((state) => state.adminData.runTimer);
  const callType = useSelector((state) => state.adminData.callType);
  const callAction = useSelector((state) => state.adminData.callAction);
  const UAList = useSelector((state) => state.adminData.UAList);
  const isPaused = useSelector((state) => state.adminData.isPaused);
  const isMicOff = useSelector((state) => state.adminData.isMicOff);
  const rtcSession = useSelector((state) => state.adminData.rtcSession);
  const isOpen = useSelector((state) => state.adminData.isOpen);
  const callId = useSelector((state) => state.adminData.callId);
  const domain = useSelector((state) => state.adminData.domain);
  const isConnectionEstablished = useSelector(
    (state) => state.adminData.isConnectionEstablished
  );

  const getTurnConfiguration = async () => {
    try {
      const orgId = localStorage.getItem('doosra-biz-organisation-id');
      if (!orgId) {
        console.error('Organisation ID not found in localStorage');
        return;
      }
      const response = await GET(
        `${cloudCallUrl}outbound/get-active-domains-organisation?organisationId=${orgId}`
      );
      if (!response?.data?.response) {
        console.error('Invalid response structure', response);
        return;
      }
      const { activeDomains = [], turnConfigs = {} } = response.data.response;
      Object.entries(turnConfigs).forEach(([key, value]) => {
        turnConfigs[key] = {
          ...value,
          credential: decryptData(value.credential),
        };
      });
      if (activeDomains.length > 0) {
        setTurnServerConfigs(turnConfigs);
      }
    } catch (error) {
      console.error('Error fetching TURN configuration:', error);
    }
  };

  useEffect(() => {
    if (isConnectionEstablished) {
      var data = JSON.stringify({
        callId,
        adminId: localStorage.getItem('doosra-biz-user-id'),
        dialer: 'solo',
        type: 'VOIP',
      });
      POST(cloudCallUrl + 'cloud-call/listen', data)
        .then((res) => {
          setCallAction({ callAction: 'listen' });
          setCallType({ callType: 'VOIP' });
        })
        .catch((err) => toast.error(err));
    }
  }, [isConnectionEstablished]);

  useEffect(() => {
    if (socket) {
      socket.on('adminListenIn', (message, cb) => {
        setCallParticipents({
          callParticipents: [
            {
              name: message.destinationNumber,
              role: 'External',
              status: 'Answered',
            },
            {
              name: message.agent,
              role: 'Agent',
              status: 'Answered',
            },
          ],
        });
        setRunTimer({ runTimer: true });
        setTimer({ timer: getCallTime(message.callStartTime) });
        setAdminRunTimer({ adminRunTimer: true });
        cb({
          status: 'Acknowledged',
        });
      });
      socket.on('adminCallEnd', (message, cb) => {
        console.log(message);
        resetValues();
        cb({
          status: 'Acknowledged',
        });
      });
      return () => {
        socket.off('adminListenIn');
        socket.off('adminCallEnd');
      };
    }
  }, [socket]);

  const resetValues = () => {
    setCallParticipents({ callParticipents: [] });
    setTimer({ timer: 0 });
    setAdminTimer({ adminTimer: 0 });
    setAdminRunTimer({ adminRunTimer: false });
    setRunTimer({ runTimer: false });
    setCallAction({ callAction: '' });
    setCallType({ callType: '' });
    setRTCSession({ rtcSession: null });
    setUAList({ UAList: [] });
    setIsPaused({ isPaused: false });
    setisMicOff({ isMicOff: false });
    setIsOpen({ isOpen: false });
    setCallId({ callId: '' });
    setIsConnectionEstablished({ isConnectionEstablished: false });
  };

  useEffect(() => {
    if (isOpen && callId) {
      makeCall();
    }
  }, [isOpen, callId]);
  useEffect(() => {
    let interval;
    if (runTimer) {
      interval = setInterval(() => {
        setTimer({});
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timer, runTimer]);

  useEffect(() => {
    let interval;
    if (adminRunTimer) {
      interval = setInterval(() => {
        setAdminTimer({});
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [adminTimer, adminRunTimer]);

  useEffect(() => {
    UAList.forEach(({ ua: UA }) => {
      UA.on('disconnected', (e) => {
        UA.unregister({ all: true });
        setIsConnectionEstablished({ isConnectionEstablished: false });
      });

      UA.on('registered', (e) => {
        console.log('user registered successfully');
        setIsConnectionEstablished({ isConnectionEstablished: true });
      });
      UA.on('registrationFailed', (e) => {
        console.log('user registration failed');
      });
      UA.on('unregistered', () => {
        console.log('user unregistration event');
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UAList]);
  useEffect(() => {
    UAList.forEach(({ ua: UA, socket }) => {
      UA.on('newRTCSession', (e) => {
        console.log('Setting the newRTCSession');
        const newSession = e.session;
        /* START ICE CONFIG */
        let iceServers = [
          {
            urls: ['stun:stun.l.google.com:19302'],
            // eslint-disable-next-line no-dupe-keys
          },
          {
            urls: ['stun:stun.ekiga.net'],
          },
        ];
        const pbx = newSession._ua._configuration.hostport_params;
        iceServers.push(turnServerConfigs[pbx]);
        options.pcConfig['iceServers'] = iceServers;
        newSession.on('icecandidate', (candidate) => {
          console.log('iceCandidate gathered - ', candidate);
        });
        /* END ICE CONFIG */
        if (newSession.direction === 'incoming' && newSession.status === 4) {
          setRTCSession({ rtcSession: newSession });

          newSession.on('accepted', function (event) {
            console.log(' Call Accepted ');
          });
          newSession.on('confirmed', function (event) {
            if (event.originator === 'remote') {
            }
          });
          // Call failed event
          newSession.on('failed', (event) => {
            console.log(' Call failed ');
            // resetValues();
          });
          // Call hungup event
          newSession.on('ended', (event) => {
            console.log(' Call hangup ');
            socket.disconnect();
            // resetValues();
          });
          // Answer Call
          if (newSession.status === 4) {
            newSession.answer(options);
            console.log('iceConnectionState - ', newSession);
          }
          // Audio connection
          newSession.connection.addEventListener('addstream', (e) => {
            var audio = new Audio();
            audio.srcObject = e.stream;
            audio.play();
          });
        }
      });
      return () => {
        UA.removeAllListeners('newRTCSession');
      };
    });
  }, [UAList]);
  return isOpen ? (
    <div
      style={{
        background: 'white',
        // display: isOpen ? 'block' : 'none',
        display: 'block',
        width: '300px',
        height: '444px',
        borderRadius: '5px',
        boxShadow: '0px 0px 4px 0px black',
        position: 'absolute',
        right: `10%`,
        top: '15%',
        zIndex: '10',
        borderRadius: '12px',
        overflow: 'hidden',
      }}
    >
      <GradientDiv>
        <div
          style={{
            position: 'absolute',
            marginTop: '24px',
            marginLeft: '24px',
          }}
        >
          <img src={AlohaaLogo} width={24} height={20}></img>
        </div>
        <span className="callWidgetText">Call widget </span>
      </GradientDiv>
      <CallDescription>
        <span className={getCallTypeDescClass()}>{getCallActionText()}</span>
      </CallDescription>
      <CallParticipentsDiv>
        {callParticipents.map((participent) => {
          return (
            <CallPraticipentDiv>
              <div>
                <label className="callPraticipentName">
                  {participent.name}
                </label>
                <label className="callPraticipentDuration">
                  {participent.role === 'Admin'
                    ? formatTime(adminTimer)
                    : formatTime(timer)}
                </label>
              </div>
              <div>
                <p className="callPraticipentDesc">{participent.role}</p>
                <p className="callPraticipentStatus">{participent.status}</p>
              </div>
            </CallPraticipentDiv>
          );
        })}
      </CallParticipentsDiv>
      <FooterDiv>
        {/* {!callAction && (
          <button
            onClick={() => makeCall()}
            style={{ position: 'absolute', left: '20px', top: '10px' }}
          >
            Make Call
          </button>
        )} */}
        {callAction === 'listen' && <ListenFooter></ListenFooter>}
        {callAction === 'barge' && <BargeFooter></BargeFooter>}
        {callAction === 'whisper' && <WhisperFooter></WhisperFooter>}
      </FooterDiv>
    </div>
  ) : null;
};

const mapStateToProps = (state) => ({});

export default commonStyle(
  connect(mapStateToProps, {
    setCallParticipents,
    setTimer,
    setRunTimer,
    setCallType,
    setCallAction,
    setUAList,
    setRTCSession,
    setIsPaused,
    setisMicOff,
    setAdminRunTimer,
    setAdminTimer,
    setIsOpen,
    setCallId,
    setIsConnectionEstablished,
  })(AdminDailPad)
);
