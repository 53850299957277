import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import SentimentScoreComponent from '../component/SentimentScoreComponent';
import TopPerformersComponent from '../component/TopPerformersComponent';
import positiveLight from "../../../assets/images/positive-light.svg";
import "../index.css";
import CloudComponent from '../component/CloudComponent';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GET } from '../../../services/HttpRequests';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { Button } from 'react-bootstrap';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

const moment = require('moment-timezone');

const BIZ_ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_API;

const sampleAnalyzedData = {
  totalCalls: 500,
  sentimentScore: 3.55,
  noOfPositiveCalls: 150,
  noOfPositiveCallsPercentage: 30,
  noOfNegativeCalls: 100,
  noOfNegativeCallsPercentage: 20,
  noOfNeutralCalls: 150,
  noOfNeutralCallsPercentage: 30,
  noOfUnclassifiedCalls: 100,
  noOfUnclassifiedCallsPercentage: 20,
  topPerformers: [
    { name: 'Agent1', count: 100, percentage: 20 },
    { name: 'Agent2', count: 100, percentage: 20 },
    { name: 'Agent3', count: 100, percentage: 20 }
  ],
  negativePerformers: [
    { name: 'Agent1', count: 100, percentage: 20 },
    { name: 'Agent2', count: 100, percentage: 20 },
    { name: 'Agent3', count: 100, percentage: 20 }
  ],
  sentimentkeywords: [
    { keyword: "Escalate Issue", size: 100, sentiment: "negative" },
    { keyword: "Neutral Feedback", size: 140, sentiment: "neutral" },
    { keyword: "Normal", size: 100, sentiment: "neutral" },
    { keyword: "Satisfied", size: 160, sentiment: "positive" },
    { keyword: "Good Service", size: 120, sentiment: "positive" },
    { keyword: "Needs Improvement", size: 160, sentiment: "negative" },
    { keyword: "Average Experience", size: 120, sentiment: "neutral" },
    { keyword: "Happy Customers", size: 100, sentiment: "positive" },
  ]
}

export function SentimentOverview(props) {
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorPage, setErrorPage] = useState(false);
  const [analyzedData, setAnalyzedData] = useState({});
  const [reportInputDetails, setReportInputDetails] = useState({
    callType: null,
    fromDate: null,
    toDate: null,
    countType: null,
    inputCount: null,
    secLimit: null,
  });

  const queryParams = new URLSearchParams(search);
  const id = queryParams.get("id");

  const history = useHistory();

  useEffect(() => {
    if (id) {
      fetchReportSentimentOverview(id);
      setErrorPage(false);
    } else {
      toast.error('no report id from url');
      setErrorPage(true);
    }
  }, [search]);

  const formatAnalyzedData = (analyzedData, metadata) => {

    const data = {
      sentimentScore: Math.round((analyzedData?.avgSentimentScore || 0) * 100) / 100,
      totalCalls: parseInt(metadata?.count || 0),
      positiveCalls: parseInt(analyzedData?.classifiedCallCounts?.Positive || 0),
      positiveCallsPercentage: 0,
      negativeCalls: parseInt(analyzedData?.classifiedCallCounts?.Negative || 0),
      negativeCallsPercentage: 0,
      neutralCalls: parseInt(analyzedData?.classifiedCallCounts?.Neutral || 0),
      neutralCallsPercentage: 0,
      unClassifiedCalls: parseInt(analyzedData?.classifiedCallCounts?.unClassified || 0),
      unClassifiedCallsPercentage: 0,
      topPerformingAgents: analyzedData?.topPerformingAgents || [],
      lowPerformingAgents: analyzedData?.lowPerformingAgents || [],
      topSentimentKeywords: analyzedData.topSentimentKeywords || []
    };

    data.totalCalls = data.positiveCalls + data.negativeCalls + data.neutralCalls + data.unClassifiedCalls;

    if (data.totalCalls && data.positiveCalls) {
      data.positiveCallsPercentage = parseInt((data.positiveCalls / data.totalCalls) * 100);
    }

    if (data.totalCalls && data.negativeCalls) {
      data.negativeCallsPercentage = parseInt((data.negativeCalls / data.totalCalls) * 100);
    }

    if (data.totalCalls && data.neutralCalls) {
      data.neutralCallsPercentage = parseInt((data.neutralCalls / data.totalCalls) * 100);
    }

    if (data.totalCalls && data.unClassifiedCalls) {
      data.unClassifiedCallsPercentage = parseInt((data.unClassifiedCalls / data.totalCalls) * 100);
    }

    setAnalyzedData(data);
    setReportInputDetails(metadata);
  }

  const fetchReportSentimentOverview = async (reportId) => {
    try {
      setIsLoading(true);
      const data = {
        reportId,
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      }
      const responseData = await GET(BIZ_ANALYTICS_URL + 'v1/report', data);
      const resultData = responseData?.data?.response?.reportsData?.data;
      if (resultData?.length) {
        formatAnalyzedData(resultData[0]?.analysedData, resultData[0]?.metaData);
        setErrorPage(false);
      } else {
        setErrorPage(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.log('Error in fetchReportSentimentOverview', error);
      setIsLoading(false);
    }
  }

  const ReportInputDetailsComponent = (props) => {
    const formatUIBasedOnKey = (key, value) => {
      if (!value) return null;
      if (!key) return null;
      if (key === "_id") return null;

      const labelMap = {
        fromDate: "FROM DATE",
        toDate: "TO DATE",
        callType: "CALL TYPE",
        countType: "COUNT TYPE",
        secLimit: "SECONDS LIMIT",
        inputCount: "INPUT COUNT"
      };

      const formattedValue = ["fromDate", "toDate"].includes(key)
        ? moment(value).tz("Asia/Kolkata").format("YYYY-MM-DD hh:mm A")
        : String(value).toUpperCase();

      return (
        <div style={{ background: "#F5F5F5", padding: 20, textAlign: "left", textTransform: "uppercase" }}>
          <b>
            {labelMap[key] || key}:
          </b>{" "}
          {formattedValue}
        </div>
      );
    };
    return <Accordion>
      <AccordionSummary
        expandIcon={<b>{'>'}</b>}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <span className='SentimentScoreContainerHeading'>Report Input Criteria</span>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '16px',
          width: '100%'
        }}>
          {
            Object.entries(props?.reportInputDetails).map(([key, value]) => formatUIBasedOnKey(key, value))
          }
        </div>

      </AccordionDetails>
    </Accordion>
  }

  const BackButton = () => {
    function handleBackButton() {
      history.push(`/quality-management`);
    }
    return <Button
      variant="outline-primary"
      style={{ fontWeight: 500 }}
      onClick={() => handleBackButton()}
    >{`< Back`}</Button>;
  }

  return (
    <div className={`${props.className}`}>
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
        <div className="ContentContainer">
          {!isLoading && !errorPage &&
            <div className="SentimentOverviewEntirePage">
              <div className='SentimentOverviewTitle'> <BackButton /> &nbsp;Sentiment Overview </div>
              <div>
                <ReportInputDetailsComponent reportInputDetails={reportInputDetails} />
              </div>
              <div className='SentimentOverviewRow1'>
                <SentimentScoreComponent
                  {...analyzedData}
                  reportId={id}
                />
              </div>
              <div className='SentimentOverviewRow2'>
                <TopPerformersComponent
                  type="top"
                  heading='Top agent with highest positive sentiment'
                  image={positiveLight}
                  performers={analyzedData?.topPerformingAgents || []}
                />
                <TopPerformersComponent
                  type="bottom"
                  heading='Top agent with highest negative sentiment'
                  image={positiveLight}
                  performers={analyzedData?.lowPerformingAgents || []}
                />
              </div>
              <div className='SentimentOverviewRow3'>
                <CloudComponent keywords={analyzedData.topSentimentKeywords || []} />
              </div>
            </div>}
          {errorPage &&
            <div className="SentimentOverviewEntirePage">
              <div className='SentimentOverviewTitle'> No Data Available </div>
            </div>}
          {isLoading && <PreLoader />}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {})(SentimentOverview));
