import moment from 'moment';
import { DELETE } from '../services/HttpRequests';
const userUrl = process.env.REACT_APP_USER_API;

export const debounce = (callback, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), delay);
  };
};

export const changeTimeFormat = (time) => {
  if (time) {
    return moment(time, ['HH.mm']).format('hh:mm A');
  }
  return '-';
};

export const displayUserCallBalance = (callBalance) => {
  if (callBalance) {
    return callBalance / 100
    // const roundOffCallBalance = Math.floor(callBalance / 60);
    // return roundOffCallBalance || null;
  }
};

export const enableFeaturesByOrg = () => {
  // to enable user Time and minutes call for an organisation add org Id to orgIds array
  // then remove ! form return
  const loginOrgId = localStorage.getItem('doosra-biz-organisation-id');
  const orgIds = [];
  return !orgIds.includes(loginOrgId);
};

export const isSuperAdminWithPermission = (type) => {
  const isSuperAdmin =
    localStorage.getItem('doosra-biz-user-role-is-super-admin') === 'true';
  if (!isSuperAdmin) {
    return true; // passing true for org admin
  }
  if (!type) {
    return false;
  }
  const superAdminPermission = JSON.parse(
    localStorage.getItem('doosra-biz-super-admin-permissions')
  );

  if (type === 'ADD') {
    return isSuperAdmin && superAdminPermission?.add;
  }
  if (type === 'EDIT') {
    return isSuperAdmin && superAdminPermission?.edit;
  }
  if (type === 'DELETE') {
    return isSuperAdmin && superAdminPermission?.delete;
  }

  return false;
};

export const logoutHandler = () => {
  DELETE(`${userUrl}v1/auth/logout`)
    .then((res) => {
      if (res?.data?.response?.user_id) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
        this.setState({ isLoading: false });
      }
    })
    .catch((err) => {
      console.log(err);
      this.setState({ isLoading: false });
    });
};
