import React, { useState, useEffect } from 'react';
import { commonStyle } from '../../../assets/css/css';
import { connect } from 'react-redux';
import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';

import { GET } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { TableContainer } from '@material-ui/core';
import moment from 'moment';
export const TOPBAR_DATA_SUCCESS = 'TOPBAR_DATA_SUCCESS';
const planAvailable = localStorage.getItem('is_plan_available');
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;

const CreditsHistoryTable = () => {
    const [history, setHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
  
  
    const fetchDataHandler = () => {
      const data = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        searchType: 'isdeleted',
        searchText: 'false',
      };
      setIsLoading(true);
      GET(`${orgUrl}v1/credit-history`, data)
        .then((res) => {
            const unFiltered = res?.data?.response || [];
            const filtered = unFiltered.filter((obj) => Object.keys(obj).indexOf("amountPaid") !== -1);
            setHistory(filtered);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setIsLoading(false);
        });
    };
  
    useEffect(() => {
      fetchDataHandler();
    }, []);
    
  
    return (
      <div>
    
  
        {isLoading ? (
          <PreLoader />
        ) : history.length ? (
          <TableContainer>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Credits Added</th>
                  <th>Amount Paid</th>
                  {/* <th >Blocked</th> */}
                  <th>Unit Cost</th>
                  
                </tr>
              </thead>
              <tbody>
                {history.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.createdAt
                        ? moment(item.createdAt)
                          .tz('Asia/Kolkata')
                          .format('DD/MM/YY')
                        : '-'}
                    </td>
                    <td>{item.credits ? item.credits /100 : "-"}</td>
                    {/* <td>{item?.isBlocked ? 'yes' : 'no'}</td> */}
                    <td>{item.amountPaid || "Free"}</td>
                    <td>{item.amountPaid ? ((item.amountPaid) / (item.credits /100)).toFixed(2): "Free"}</td>
                    
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>
        ) : (
          <div style={{ color: 'brown', fontWeight: 500, textAlign: 'center' }}>
          </div>
        )}
      </div>
    );
  };
const CreditHistory = (props) => {
  const tabs = [
    {
      title: 'Account',
      active: false,
      subtitle: ``,
      onClick: () => props.history.push('/account-settings'),
    },
    {
      title: 'Credit History',
      active: true,
      subtitle: ``,
      onClick: () => this.props.history.push('/credit-history'),
    },
    {
      title: 'Operating Schedule',
      active: false,
      subtitle: ``,
      onClick: () => props.history.push('/off-hours'),
    },
    {
      title: 'Call Settings',
      active: false,
      subtitle: ``,
      onClick: () => props.history.push('/call-settings'),
    },
    {
      title: 'Agent Settings',
      active: false,
      subtitle: ``,
      onClick: () => props.history.push('/agent-settings'),
    },
  ];
  if (planAvailable === true || planAvailable === 'true') {
    // if (showPaymentHistory) {
    //   tabs.push({
    //     title: 'Payment History',
    //     active: false,
    //     subtitle: ``,
    //     onClick: () => props.history.push('/payment-history'),
    //   });
    // }
    tabs.push({
      title: 'KYC',
      active: false,
      subtitle: ``,
      onClick: () => props.history.push('/kyc'),
    });
  }

  const CreditTabs = [
    {
      title: 'Credit Purchase',
      active: true,
      subtitle: ``,
      onClick: () => props.history.push('/credit-history'),
    },
    {
      title: 'Credit Consumption',
      active: false,
      subtitle: ``,
      onClick: () => props.history.push('/credit-consumption'),
    }
  ]

  return (
    <div className={`${props.className}`}>
      <Topbar />
      <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
        <Sidebar />
         <div className="ContentContainer">
            <TabSearchBar type={'creditHistory'} tabs={CreditTabs}></TabSearchBar>
        <div className="ContentContainer">
            <CreditsHistoryTable></CreditsHistoryTable>
        </div>
        </div>
      </div>
    </div>
  );
};

// border: solid 1px #d2d3d4;
//     border-radius: 4px;
//     // max-width:300px;
//     padding:12px;
//     flex:1;
//     box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  agentEmailVerification: state.topbar.agentEmailVerification,
});

export default commonStyle(connect(mapStateToProps, {})(CreditHistory));
