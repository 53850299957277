export const setCallParticipents = (data) => (dispatch) => {
  console.log('SET_CALL_PARTICIPENTS dispatch', data);
  dispatch({
    type: 'SET_CALL_PARTICIPENTS',
    payload: data,
  });
};

export const setTimer = (data) => (dispatch) => {
  console.log('SET_TIMER', data);
  dispatch({
    type: 'SET_TIMER',
    payload: data,
  });
};

export const setAdminTimer = (data) => (dispatch) => {
  console.log('SET_ADMIN_TIMER', data);
  dispatch({
    type: 'SET_ADMIN_TIMER',
    payload: data,
  });
};

export const setRunTimer = (data) => (dispatch) => {
  console.log('SET_RUN_TIMER');
  dispatch({
    type: 'SET_RUN_TIMER',
    payload: data,
  });
};

export const setAdminRunTimer = (data) => (dispatch) => {
  console.log('SET_ADMIN_RUN_TIMER');
  dispatch({
    type: 'SET_ADMIN_RUN_TIMER',
    payload: data,
  });
};

export const setCallType = (data) => (dispatch) => {
  console.log('SET_CALL_TYPE');
  dispatch({
    type: 'SET_CALL_TYPE',
    payload: data,
  });
};

export const setCallAction = (data) => (dispatch) => {
  console.log('SET_CALL_ACTION');
  dispatch({
    type: 'SET_CALL_ACTION',
    payload: data,
  });
};

export const setUAList = (data) => (dispatch) => {
  console.log('SET_UA_LIST');
  dispatch({
    type: 'SET_UA_LIST',
    payload: data,
  });
};

export const setRTCSession = (data) => (dispatch) => {
  console.log('SET_RTC_SESSION');
  dispatch({
    type: 'SET_RTC_SESSION',
    payload: data,
  });
};

export const setIsPaused = (data) => (dispatch) => {
  console.log('SET_IS_PAUSED');
  dispatch({
    type: 'SET_IS_PAUSED',
    payload: data,
  });
};

export const setisMicOff = (data) => (dispatch) => {
  console.log('SET_IS_MIC_OFF');
  dispatch({
    type: 'SET_IS_MIC_OFF',
    payload: data,
  });
};

export const setIsOpen = (data) => (dispatch) => {
  console.log("SET_IS_OPEN");
  dispatch({
    type: "SET_IS_OPEN",
    payload: data,
  })
}

export const setCallId = (data) => (dispatch) => {
  console.log("SET_CALL_ID");
  dispatch({
    type: "SET_CALL_ID",
    payload: data,
  })
}

export const setDomian = (data) => (dispatch) => {
  console.log("SET_DOMAIN");
  dispatch({
    type: "SET_DOMAIN",
    payload: data,
  })
}

export const setIsConnectionEstablished = (data) => (dispatch) => {
  console.log("SET_IS_CONNECTION_ESTABLISHED");
  dispatch({
    type: "SET_IS_CONNECTION_ESTABLISHED",
    payload: data
  })
}