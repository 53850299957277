import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import Close from '../../../assets/images/popupClose.png';
import Delete from '../../../assets/images/deleteWRedBackground.png';

class PopThree extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      name: '',
    };
  }

  componentDidMount() {
    this.setState({
      name: this.props.name,
      number: this.props.number
    });
  }

  openPopup = () => {
    this.setState({ popupOpen: true });
  };
  closePopup = () => {
    this.setState({ popupOpen: false });
  };
  inputHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  deleteUserAndClosePopup = (userId) => {
    this.props.deleteUser(userId);
    this.setState({ popupOpen: false });
  };

  render() {
    let popper =
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer" style={{ overflow: 'auto' }}>
          <div className="PopupHeaderContainer">
            <p>Delete Manager Profile</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={this.closePopup}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupBodyTitle">
              Are you sure you want to delete this manager?
            </p>

            <div className="PopupInputContainer">
              <label className="PopupInputLabel">Name</label>
              <input
                className="PopupInput ReadOnlyInput"
                id="an"
                readOnly={true}
                value={this.state.name}
                onChange={this.inputHandler}
              />
            </div>
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">Personal Phone Number</label>
              <input
                className="PopupInput ReadOnlyInput"
                id="appn"
                readOnly={true}
                value={this.state.number}
                onChange={this.inputHandler}
              />
            </div>

            <p className="PopupNote">
              <b>Note:</b> Deleting Manager will remove your assigned agents.
            </p>

            <button
              className="ButtonFullWidth BgRed"
              onClick={() => this.deleteUserAndClosePopup(this.props.userId)}
            >
              REMOVE
            </button>
          </div>
        </div>
      </Popup>;

    return (
      <React.Fragment>
        <div style={{ padding: '5px', cursor: 'pointer' }}>
          <img alt="" onClick={this.openPopup} src={Delete} />
        </div>
        {popper}
      </React.Fragment>
    );
  }
}

export default PopThree;
