import BubbleUI from "react-bubble-ui";
import "react-bubble-ui/dist/index.css";
import "../index.css";
import { Tooltip } from "@material-ui/core";

const BubbleCssClasses = {
  positive: 'GreenBubble',
  neutral: 'BlueBubble',
  negative: 'RedBubble'
};

export default function CloudComponent(props) {
  const options = {
    size: 150,
    minSize: 90,
    gutter: 6,
    provideProps: true,
    numCols: 6,
    fringeWidth: 50,
    yRadius: 150,
    xRadius: 150,
    cornerRadius: 20,
    showGuides: false,
    compact: true,
    gravitation: 2,
  };

  const getSize = (count) => {
    const minSize = 130;
    const maxSize = 150;
    const scaledSize = minSize + (count / 100) * (maxSize - minSize); // Scale size based on count
    return Math.max(minSize, Math.min(scaledSize, maxSize)); // Keep it within range
  };

  const getBubbleClass = (count) => {
    // if (count > 5) return BubbleCssClasses.positive;
    // if (count > 3) return BubbleCssClasses.neutral;
    return BubbleCssClasses.neutral;
  };

  const children = props?.keywords?.map((data, i) => {
    const bubbleSize = getSize(data.count);
    const bubbleClass = getBubbleClass(data.count);

    return (
      <Tooltip title={`Frequency: ${data.count || 0}`} arrow placement="top">
        <div
          className={`Bubble ${bubbleClass}`}
          style={{ width: `${bubbleSize}px`, height: `${bubbleSize}px`, lineHeight: `${bubbleSize}px` }}
        >
          {data.word}
        </div>
      </Tooltip>
    );
  });

  return (
    <div className="CloudComponent">
      <div className='CloudContainerHeader'>
        <div className='CloudContainerHeading'>Topic Cloud</div>
      </div>
      <hr />
      <div className='BubbleComponent'>
        <BubbleUI options={options} className='myBubbleUI'>
          {children}
        </BubbleUI>
      </div>
    </div>
  );
}