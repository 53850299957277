import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import '../styles/Admin.css';
import { EditIcon } from '../../../assets/Icons/Icons';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';

class EditAdminPopup extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      name: '',
      number: '',
      email: '',
      accessRole: 'admin',
      nameUpdated: false,
      numberUpdated: false,
      emailUpdated: false,
      error: '',
      step: 1
    };
    this.initialState = this.state;
  }

  componentDidMount() {
    this.setState({
      name: this.props.name,
      number: this.props.number,
      email: this.props.email,
      accessRole: this.props.accessRole
    });
  }

  openPopup = () => {
    this.initialState = { ...this.initialState, ...this.state };
    this.setState(this.initialState);
    this.setState({ popupOpen: true });
  };
  closePopup = () => {
    this.setState({ ...this.initialState });
    this.setState({ popupOpen: false });
  };
  clearCallTime = () => {
    if (this.state.startTime || this.state.endTime) {
      this.setState({ endTimeUpdated: true });
    }
    this.setState({ startTime: '', endTime: '' });
  };
  inputHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    if (e.target.id === 'name') {
      if (e.target.value !== this.props.name) {
        this.setState({ nameUpdated: true });
      } else {
        this.setState({ nameUpdated: false });
      }
    }
    if (e.target.id === 'number') {
      if (e.target.value !== this.props.number) {
        this.setState({ numberUpdated: true });
      } else {
        this.setState({ numberUpdated: false });
      }
    }
    if (e.target.id === 'email') {
      if (e.target.value !== this.props.email) {
        this.setState({ emailUpdated: true });
      } else {
        this.setState({ emailUpdated: false });
      }
    }
    if (e.target.id === 'accessRole') {
      this.setState({ emailUpdated: e.target.value });
    }
  };

  onSubmit = (userId) => {

    if (this.state.step === 1
      && this.props.accessRole === 'manager'
      && this.props.accessRole !== this.state.accessRole) {
      this.setState({ step: 2 });
    } else {
      console.log('updateUserAndClosePopup');
      this.updateUserAndClosePopup(userId);
    }
  }

  updateUserAndClosePopup = (userId) => {
    const data = {
      name: this.state.name,
      phone_number: this.state.number,
      email: this.state.email,
      accessType: this.state.accessRole
    };
    this.props.editUser(
      userId,
      data,
      () => {
        if (this.props.editUserError) {
          this.setState({
            popupOpen: true,
            error: this.props.editUserError,
          });
        } else {
          this.setState({
            ...this.initialState,
            name: this.props.name,
            number: this.props.number,
            email: this.props.email,
            popupOpen: false,
            step: 1
          });
        }
      }
    );
  };

  render() {
    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer" style={{ overflow: 'auto' }}>
          <div className="PopupHeaderContainer">
            <p>Edit Manager Info</p>
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={this.closePopup}
            />
          </div>
          <div className="PopupBody">

            {this.state.step === 1 && <>
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">Name</label>
                <input
                  className="PopupInput"
                  id="name"
                  value={this.state.name}
                  onChange={this.inputHandler}
                />
              </div>
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">Personal Phone Number</label>
                <input
                  className="PopupInput"
                  id="number"
                  value={this.state.number}
                  onChange={this.inputHandler}
                />
              </div>
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">Email</label>
                <input
                  className="PopupInput"
                  id="email"
                  value={this.state.email}
                  onChange={this.inputHandler}
                />
              </div>
              <div className="PopupInputContainer">
                <label className="PopupInputLabel">Access Role</label>
                <br />
                <div style={{ display: 'flex', padding: '0px 10px 0px 10px', gap: '30px' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    Admin &nbsp;
                    <input
                      type='radio'
                      id='accessRole'
                      value='admin'
                      checked={this.state.accessRole === 'admin'}
                      onChange={this.inputHandler}
                    />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    Manager &nbsp;
                    <input
                      type='radio'
                      id='accessRole'
                      value='manager'
                      checked={this.state.accessRole === 'manager'}
                      onChange={this.inputHandler}
                    />
                  </div>
                </div>
              </div></>}

            {this.state.step === 2 && <>
              <div className="AccessRoleWarning">
                Changing your role from &nbsp;
                <b style={{ color: '#287dc9' }}>Manager</b> to &nbsp;
                <b style={{ color: '#287dc9' }}>Admin</b> will remove your assigned agents. Do you want to proceed?
              </div>
            </>}

            {this.state.error ? (
              <div
                className="ErrorContainer ErrorContainerMargin"
                style={{ margin: 10 }}
              >
                <img
                  alt=""
                  src={InfoRed}
                  style={{
                    paddingRight: '5px',
                    position: 'relative',
                    top: '-2px',
                  }}
                />
                {this.state.error}
              </div>
            ) : null}
            <button
              className="ButtonFullWidth"
              type="submit"
              onClick={() => this.onSubmit(this.props.userId)}
            >
              {this.state.step === 2 ? 'CONFIRM' : 'SAVE'}
            </button>
            <button className="ButtonCancelFullWidth" onClick={this.closePopup}>
              CANCEL
            </button>
          </div>
        </div>
      </Popup >
    );

    return (
      <React.Fragment>
        <div style={{ padding: '5px', cursor: 'pointer' }}>
          <EditIcon onClick={this.openPopup} />
        </div>
        {popper}
      </React.Fragment>
    );
  }
}

export default EditAdminPopup;
