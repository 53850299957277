import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { Table } from 'react-bootstrap';
import "../index.css";

function TopPerformersComponent({ type, heading, image, performers }) {
  return (
    <div className='TopPerformersContainer'>
      <div className='TopPerformersHeader'>
        <div className='TopPerformersHeading'>{heading}</div>
        <div className='TopPerformersHeaderImage'>
          {type === "top" ? `😊` : `🙁`}
          {/* <img src={image} className='TopPerformersHeaderImage' /> */}
        </div>
      </div>
      <div className='TopPerformersBody'>
        <TableContainer style={{ maxHeight: "300px", overflow: "auto" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className='TopPerformersTableHeader' style={{ width: "10%" }}>&nbsp;</TableCell>
                <TableCell className='TopPerformersTableHeader' style={{ width: "60%" }}>Agent name</TableCell>
                <TableCell className='TopPerformersTableHeader' style={{ width: "20%" }} align='right'>
                  Sentiment score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {performers.map((i, index) => (
                <TableRow key={i._id} style={index % 2 === 1 ? {
                  background: 'rgba(175, 82, 222, 0.05)'
                } : {}}>
                  <TableCell className='TopPerformersTableContent'>{index + 1}</TableCell>
                  <TableCell className='TopPerformersTableContent'>{i.name}</TableCell>
                  <TableCell className='TopPerformersTableContent' align='right'>
                    {/* {`${i.avgScore} (${i.percentage || 0}%)`} */}
                    {`${Math.round((i.avgScore || 0) * 10) / 10}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default TopPerformersComponent