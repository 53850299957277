const initialState = {
  isOpen: false,
  callId: "",
  callParticipents: [],
  adminTimer: 0,
  adminRunTimer: false,
  timer: 0,
  runTimer: false,
  callType: '',
  callAction: '',
  UAList: [],
  rtcSession: null,
  isPaused: false,
  isMicOff: false,
  domain: "",
  isConnectionEstablished: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_IS_OPEN":
      return {
        ...state,
        isOpen: action.payload.isOpen,
      }
    case "SET_CALL_ID":
      return {
        ...state,
        callId: action.payload.callId,
      }
    case 'SET_CALL_PARTICIPENTS':
      return {
        ...state,
        callParticipents: action.payload.callParticipents,
      };
    case 'SET_TIMER':
      return {
        ...state,
        timer:
          action.payload.timer || action.payload.timer === 0
            ? action.payload.timer
            : state.timer + 1,
      };
    case 'SET_ADMIN_TIMER':
      return {
        ...state,
        adminTimer:
          action.payload.adminTimer || action.payload.adminTimer === 0
            ? action.payload.adminTimer
            : state.adminTimer + 1,
      };
    case 'SET_RUN_TIMER':
      return {
        ...state,
        runTimer: action.payload.runTimer,
      };
    case 'SET_ADMIN_RUN_TIMER':
      return {
        ...state,
        adminRunTimer: action.payload.adminRunTimer,
      };
    case 'SET_CALL_TYPE':
      return {
        ...state,
        callType: action.payload.callType,
      };
    case 'SET_CALL_ACTION':
      return {
        ...state,
        callAction: action.payload.callAction,
      };
    case 'SET_UA_LIST':
      return {
        ...state,
        UAList: action.payload.UAList,
      };
    case 'SET_RTC_SESSION':
      return {
        ...state,
        rtcSession: action.payload.rtcSession,
      };
    case 'SET_IS_PAUSED':
      return {
        ...state,
        isPaused: action.payload.isPaused,
      };
    case 'SET_IS_MIC_OFF':
      return {
        ...state,
        isMicOff: action.payload.isMicOff,
      };
    case "SET_DOMAIN":
      return {
        ...state,
        domain: action.payload.domain
      }
    case "SET_IS_CONNECTION_ESTABLISHED":
      return {
        ...state,
        isConnectionEstablished: action.payload.isConnectionEstablished
      }
    default:
      return state;
  }
}
