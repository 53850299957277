import React from "react";
import { Pie } from "react-chartjs-2";

function SentimentPieChart(props) {
  const chartDisplayData = {
    labels: ["Positive", "Neutral", "Negative", "Unclassified"],
    datasets: [{
      label: "count",
      data: [
        props?.positiveCalls || 0,
        props?.neutralCalls || 0,
        props?.negativeCalls || 0,
        props?.unClassifiedCalls || 0
      ],
      backgroundColor: ["#85E0A3", "#FFD966", "#FFAFA3", "#D4D4D4"]
    }]
  };
  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Sentiment Analysis Chart',
        position: "top",
        padding: {
          top: 20,
          bottom: -40,
        },
      },
      legend: {
        position: "right",
        lables: {
          usePointStyle: true,
        }
      }
    }
  };
  return <Pie data={chartDisplayData} options={options} />
}

export default SentimentPieChart;