import React, { useRef, useState, useEffect } from 'react';
import CsvDownloader from 'react-csv-downloader';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { GET } from '../../../services/HttpRequests';
import moment from 'moment';
import {
  CALL_LOGS_EXPORT_BUTTON,
  ADMIN_CALL_LOGS_EXPORT_BUTTON,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';
const incomingCallsUrl = process.env.REACT_APP_INCOMING_CALLS_API;

export default function ExportCallLogsPopup() {
  const [list, setList] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const csvLink = useRef(null);
  const userRole = localStorage.getItem('doosra-biz-user-role');
  const [designBoxOrganisationId, setDesignBoxOrganisationId] = useState("");


  useEffect(() => {
    const organisationId = localStorage.getItem('doosra-biz-organisation-id');
    if (organisationId === "d9c67ec2-fe1d-4284-89e6-acb173c55229") {
      setDesignBoxOrganisationId(organisationId);
    }
  }, []);

  let org_name = localStorage.getItem('doosra-biz-organisation-name') || '';
  let columns = [
    { id: 's_mobile_no', displayName: 'CALLER' },
    { id: 'call_type', displayName: 'CALL TYPE' },
    { id: 'dtmf_codes', displayName: 'DTMF CODE' },
    { id: 'call_status_text', displayName: 'CALL STATUS' },
    { id: 'zvr_name', displayName: 'AGENT NAME' },
    { id: 'v_mobile_no', displayName: 'VIRTUAL NUMBER' },
    { id: 'call_duration', displayName: 'DURATION' },
    { id: "call_recording_url", displayName: "CALL_RECORDING_URL" },
    { id: 'pulse_count', displayName: 'PULSE COUNT' },
    { id: 'created_at_date', displayName: 'DATE' },
    { id: 'created_at_time', displayName: 'TIME' },
    { id: 'disposition', displayName: 'CALL DISPOSITION' },
    { id: "callComment", displayName: "CALL COMMENT" },
  ];
  if (designBoxOrganisationId) {
    columns = [
      { id: 's_mobile_no', displayName: 'CALLER' },
      { id: 'dtmf_codes', displayName: 'DTMF CODE' },
      { id: 'call_status', displayName: 'CALL STATUS' },
      { id: 'v_mobile_no', displayName: 'VIRTUAL NUMBER' },
      { id: 'call_duration', displayName: 'DURATION' },
      { id: 'created_at_date', displayName: 'DATE' },
      { id: 'created_at_time', displayName: 'TIME' },
      { id: 'disposition', displayName: 'CALL DISPOSITION' },
      { id: "callComment", displayName: "CALL COMMENT" },
    ];
  }

  const openPopup = () => {
    setIsPopupOpen(true);
    setFetching(false);
    setFromDate(null);
    setToDate(null);
  };

  const fetchDataHandler = async () => {
    try {
      if (fetching) return;
      setFetching(true);
      let docs = [];
      let isDownloadComplete = true;
      let counter = 1;
      const pageSize = 3000;
      while (isDownloadComplete) {
        let url = `${incomingCallsUrl}v1/organisations/calls`;
        if (designBoxOrganisationId) {
          url = `${incomingCallsUrl}v1/organisations/calls/export`;
        }
        const res = await GET(url, {
          pageNo: counter,
          pageSize,
          organisation_id: localStorage.getItem('doosra-biz-organisation-id'),
          searchKey: 'date',
          search_key: 'date',
          from_date: fromDate,
          to_date: designBoxOrganisationId ? fromDate : toDate,
          is_export: true
        });
        docs = [...docs, ...res.data.response.payload.docs];
        if (res.data.response.payload.docs?.length < pageSize) {
          isDownloadComplete = false;
        }
        counter++;
      }
      setList(docs);
      csvLink.current.handleClick();
      setFetching(false);
      setIsPopupOpen(false);
    } catch (err) {
      console.log(err);
      setFetching(false);
    }
  };

  return (
    <div>
      <button
        className="Otherbutton"
        onClick={() => {
          logPostHogEvent(
            userRole === 'ADMIN'
              ? ADMIN_CALL_LOGS_EXPORT_BUTTON
              : CALL_LOGS_EXPORT_BUTTON
          );
          openPopup();
        }}
      >
        EXPORT
      </button>
      <Popup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Download call logs</p>
            <img
              className="PopupClose"
              src={Close}
              onClick={() => {
                setIsPopupOpen(false);
                setFetching(false);
                setFromDate(null);
                setToDate(null);
              }}
            />
          </div>
          <div className="PopupBody">
            <p className="PopupInfo">
              The logs will be downloaded as a CSV file.
            </p>
            {
              designBoxOrganisationId ?
                <div style={{ margin: '8px 0 0 40px' }}>
                  <p style={{ margin: 0 }}>Date</p>
                  <input
                    type="date"
                    onChange={(e) => {
                      setFromDate(e.target.value);
                      setToDate(e.target.value);
                    }}
                    value={fromDate}
                  />
                </div>
                :
                <div>
                  <div style={{ margin: '8px 0 0 40px' }}>
                    <p style={{ margin: 0 }}>From Date</p>
                    <input
                      type="date"
                      onChange={(e) => setFromDate(e.target.value)}
                      value={fromDate}
                    />
                  </div>
                  <div style={{ margin: '8px 0 0 40px' }}>
                    <p style={{ margin: 0 }}>To Date</p>
                    <input
                      min={fromDate}
                      disabled={!fromDate}
                      type="date"
                      onChange={(e) => setToDate(e.target.value)}
                      value={toDate}
                    />
                  </div>
                </div>
            }
            {/* <div style={{ margin: '8px 0 0 40px' }}>
              <p style={{ margin: 0 }}>From Date</p>
              <input
                type="date"
                onChange={(e) => setFromDate(e.target.value)}
                value={fromDate}
              />
            </div> */}
            {/* <div style={{ margin: '8px 0 0 40px' }}>
              <p style={{ margin: 0 }}>To Date</p>
              <input
                min={fromDate}
                disabled={!fromDate}
                type="date"
                onChange={(e) => setToDate(e.target.value)}
                value={toDate}
              />
            </div> */}
            <p className="PopupNote">
              {designBoxOrganisationId ? `Max download limit - Recent 50000 entries` : `Max download limit - Recent 5000 entries`}
            </p>
            <CsvDownloader
              filename={`${org_name}_incoming_call_list_${new Date().toLocaleDateString()}`}
              separator=","
              columns={columns}
              ref={csvLink}
              datas={
                list.length
                  ? list.map((detail) => {
                    let dtmfCodes = null;
                    let dtmfcodesArray = detail?.ivr_details?.filter(
                      (obj) => obj.dtmf_code !== 'timeout'
                    );
                    dtmfcodesArray = dtmfcodesArray?.map(
                      (obj) => obj.dtmf_code
                    );
                    if (dtmfcodesArray && dtmfcodesArray.length > 0)
                      dtmfCodes = dtmfcodesArray.join(' - ');

                    const callType = detail.ivrName
                      ? 'IVR'
                      : detail.routed_group
                        ? 'Group'
                        : detail.auto_attendant_id
                          ? 'Auto Attendant'
                          : '-';
                    if (designBoxOrganisationId) {
                      return {
                        ...detail,
                        created_at_date: new Date(
                          detail.created_at
                        ).toLocaleDateString(),
                        call_status_text: `${detail.call_status_text ? detail.call_status_text : ''
                          }`,
                        created_at_time: new Date(
                          detail.created_at
                        ).toLocaleTimeString(),
                        call_type: callType,
                        dtmf_codes: dtmfCodes || '-',
                      };
                    }

                    return {
                      ...detail,
                      created_at_date: moment(detail.created_at).format("DD-MM-YYYY"),
                      call_status_text: `${detail.call_status_text ? detail.call_status_text : ''
                        }`,
                      created_at_time: moment(detail.created_at).format("HH:mm:ss"),
                      call_type: callType,
                      dtmf_codes: dtmfCodes || '-',
                    };
                  })
                  : [{}]
              }
              text=""
              style={{ display: 'none' }}
            />

            <button
              className={
                !fromDate || !toDate
                  ? 'ButtonFullWidth BgGrey'
                  : 'ButtonFullWidth'
              }
              style={
                !fromDate || !toDate || fetching ? { cursor: 'default' } : null
              }
              disabled={designBoxOrganisationId ? !fromDate : !fromDate || !toDate}
              onClick={fetchDataHandler}
            >
              {fetching ? 'Loading ...' : 'Download CSV'}
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
}
