import { combineReducers } from 'redux';
import sidebar from './sidebar';
import system from './system';
import topbar from './topBar';
import recovery from './recoveryReducer';
import onboardingtour from './onboardingtour';
import dailPadReducer from './dailPadReducer';
import callFlow from './callFlowReducer';
import adminReducer from "./adminReducer"

const appReducer = combineReducers({
  sidebar: sidebar,
  topbar: topbar,
  system: system,
  recovery: recovery,
  onboardingtour: onboardingtour,
  dailpad: dailPadReducer,
  callFlow:  callFlow,
  adminData: adminReducer,
});

export default appReducer;
