import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import { POST } from '../../../services/HttpRequests';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import { isSuperAdminWithPermission } from '../../../utility/utility';
import {
  ADMIN_ADD_AGENT,
} from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';

const userUrl = process.env.REACT_APP_USER_API;
class PopTwo extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      name: '',
      number: '',
      email: '',
      error: '',
      processing: false,
      accessRole: 'admin'
    };
    this.initialState = this.state;
  }

  openPopup = () => {
    logPostHogEvent(ADMIN_ADD_AGENT);
    this.setState({ popupOpen: true });
  };
  closePopup = () => {
    this.setState(this.initialState);
  };
  inputHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  manualDataHandler = () => {
    if (!this.state.name || !this.state.email || !this.state.number) {
      this.setState({
        error: 'Name, Phone Number and Email are required fields.',
      });
      return;
    }
    if (this.state.processing) return;
    this.setState({ processing: true });

    let data = {
      name: this.state.name,
      phone_number: this.state.number,
      email: this.state.email,
      organisationId: localStorage.getItem('doosra-biz-organisation-id'),
      accessType: this.state.accessRole,
      sendMail: true
    };

    POST(`${userUrl}v1/admin`, data)
      .then((res) => {
        return res.data.response;
      })
      .then((res) => {
        this.closePopup();
        this.props.refreshList();
        toast.success('Admin added Successfully');
        this.setState({
          popupOpen: false,
          name: '',
          number: '',
          email: '',
          error: '',
          accessRole: 'admin',
          processing: false,
        });
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error
        ) {
          this.setState({
            error: err.response.data.error.reason,
            processing: false,
          });
          toast.error(err.response.data.error.reason);
        }
      });
  };

  render() {

    const currentView = (
      <div className="PopupContainer" style={{ overflow: 'auto' }}>
        <div className="PopupHeaderContainer">
          <p>Add a Manager</p>
          <img
            alt=""
            className="PopupClose"
            src={Close}
            onClick={this.closePopup}
          />
        </div>
        <div className="PopupInputContainer">
          <label className="PopupInputLabel">Name</label>
          <input
            className="PopupInput"
            id="name"
            value={this.state.name}
            onChange={this.inputHandler}
          />
        </div>
        <div className="PopupInputContainer">
          <label className="PopupInputLabel">Phone Number</label>
          <input
            className="PopupInput"
            id="number"
            value={this.state.number}
            onChange={this.inputHandler}
          />
        </div>
        <div className="PopupInputContainer">
          <label className="PopupInputLabel">Email</label>
          <input
            className="PopupInput"
            id="email"
            type="email"
            value={this.state.email}
            onChange={this.inputHandler}
          />
        </div>
        <div className="PopupInputContainer" style={{ paddingTop: '3px' }}>
          <label className="PopupInputLabel">Access Role</label>
          <br />
          <div style={{ display: 'flex', padding: '0px 20px 0px 0px', gap: '40px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>

              <input
                type='radio'
                id='accessRole'
                value='admin'
                checked={this.state.accessRole === 'admin'}
                onChange={this.inputHandler}
              />&nbsp;
              Admin
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <input
                type='radio'
                id='accessRole'
                value='manager'
                checked={this.state.accessRole === 'manager'}
                onChange={this.inputHandler}
              />
              &nbsp;
              Manager
            </div>
          </div>
        </div>
        {this.state.error ? (
          <div className="ErrorContainer ErrorContainerMargin">
            <img
              alt=""
              src={InfoRed}
              style={{
                paddingRight: '5px',
                position: 'relative',
                top: '-2px',
              }}
            />
            {this.state.error}
          </div>
        ) : null}

        <p className="PopupNote">
          <b>Note:</b> Admins have organisation-wide access, while managers can only access their assigned agents.
        </p>
        <button
          className="ButtonFullWidth BgGreen"
          onClick={this.manualDataHandler}
          style={{ marginTop: '20px' }}
        >
          SAVE
        </button>
      </div>
    );


    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        {currentView}
      </Popup>
    );
    return (
      <div style={{ display: 'flex' }}>
        {this.state.processing && <PreLoader />}
        <span>
          <button
            className="Otherbutton"
            style={{ marginRight: 0 }}
            onClick={this.openPopup}
            disabled={!isSuperAdminWithPermission('ADD')}
          >
            ADD MANAGER
          </button>
          {popper}
        </span>
      </div>
    );
  }
}

export default PopTwo;
