import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_TURN_CONFIG_SECRET_KEY;
const IV = process.env.REACT_APP_TURN_CONFIG_IV;

export const decryptData = (encryptedBase64) => {
  const key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
  const iv = CryptoJS.enc.Utf8.parse(IV);
  const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};