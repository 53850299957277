import React, { Component } from 'react';
import Popup from '../../../common/component/popups/popups';
import '../../../assets/css/Popups.css';
import InfoRed from '../../../assets/images/infoRed.png';
import Close from '../../../assets/images/popupClose.png';
import AddBalance from '../../../assets/images/AddBalance.png';

class CallBalance extends Component {
  constructor() {
    super();
    this.state = {
      popupOpen: false,
      addBalanceUpdated: false,
      addBalance: 0,
      clearCallBalance: false,
      error: '',
    };
    this.initialState = this.state;
    this.availableCallMinutes = 0;
  }
  calculateAvailableCallMinutes() {
    let callMinutes = '';
    if (this.props.user?.organisation?.call_minutes_available) {
      let callMinutesAvailable =
        this.props.user?.organisation?.call_minutes_available;
      if (callMinutesAvailable > 0) {
        callMinutes = callMinutesAvailable / 100;
        // callMinutes = Math.floor(callMinutesAvailable / 60);
      }
    }
    this.availableCallMinutes = callMinutes;
  }
  componentDidMount() {
    this.setState({
      currentCallBalance: this.props.currentCallBalance || 0,
      totalCallBalance: this.props.totalCallBalance,
      error: '',
    });
  }

  openPopup = () => {
    this.initialState = { ...this.initialState, ...this.state, error: '' };
    this.setState(this.initialState);
    this.setState({ popupOpen: true });
    this.calculateAvailableCallMinutes();
    this.setState({ addBalance: this.availableCallMinutes });
  };
  closePopup = () => {
    this.setState({ ...this.initialState, error: '' });
    this.setState({ popupOpen: false });
  };

  clearCallBalanceEvent = () => {
    this.setState({ clearCallBalance: !this.state.clearCallBalance });
    if (!this.state.clearCallBalance) {
      this.setState({ addBalanceUpdated: true });
    }
    this.availableCallMinutes = 0;
  };

  inputHandler = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    if (e.target.id === 'addBalanceUpdated') {
      if (e.target.value !== this.props.addBalance) {
        this.setState({ addBalanceUpdated: true, addBalance: e.target.value });
        this.availableCallMinutes = e.target.value;
      } else {
        this.setState({ addBalanceUpdated: false });
      }
    }
  };

  updateCallBalanceAndClosePopup = (userData) => {
    this.props
      .updateCallBalance(userData, {
        addBalance: this.state.addBalance,
        userData: userData,
        clearCallBalance: this.state.clearCallBalance,
      })
      .then((res) => {
        this.setState({ error: this.props.error });
        this.setState(this.initialState);
      })
      .catch((err) => {
        console.log('err');
        this.setState({ error: this.props.error });
      });
  };

  render() {
    let popper = (
      <Popup
        open={this.state.popupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="PopupContainer">
          <div className="PopupHeaderContainer">
            <p>Set agent credits</p>
            {/* <p style={{ padding: 10, fontSize: 16, fontWeight: 600, textAlign: "center" }} className="PopupInputLabel">Call Balance: {this.state.currentCallBalance}</p> */}
            <img
              alt=""
              className="PopupClose"
              src={Close}
              onClick={this.closePopup}
            />
          </div>
          <div className="PopupBody">
            <div className="PopupInputContainer">
              {/* {this.availableCallMinutes ? (
                <React.Fragment>
                  <label className="PopupInputLabel AvailableCAllBalanceLabel">
                    Current balance: {this.availableCallMinutes} Mins
                  </label>
                </React.Fragment>
              ) : null} */}
              <label className="PopupInputLabel" style={{ paddingTop: 15 }}>
                Set credits
              </label>
              <input
                className="PopupInput"
                type="number"
                min="0"
                id="addBalanceUpdated"
                disabled={this.state.clearCallBalance}
                value={this.availableCallMinutes}
                onChange={this.inputHandler}
              />
            </div>
            <a
              className="ClearCallBalance"
              onClick={this.clearCallBalanceEvent}
            >
              <u> Reset credits </u>
            </a>
            <label
              className="PopupInputLabel CallBalanceInfo"
              style={{ paddingTop: 8 }}
            >
              You can limit how many max credits an agent can use.
            </label>
            <label
              className="PopupInputLabel CallBalanceInfo"
              style={{ paddingTop: 8 }}
            >
              Reset credits if you want no restriction on this agents call
              credits
            </label>

            {this.props.error ? (
              <div className="ErrorContainer ErrorContainerMargin">
                <img
                  alt=""
                  src={InfoRed}
                  style={{
                    paddingRight: '5px',
                    position: 'relative',
                    top: '-2px',
                  }}
                />
                {this.props.error}
              </div>
            ) : null}

            {this.state.addBalanceUpdated ? (
              <button
                className="ButtonFullWidth"
                type="submit"
                onClick={() =>
                  this.updateCallBalanceAndClosePopup(this.props.user)
                }
              >
                SAVE
              </button>
            ) : (
              <button className="ButtonFullWidth" style={{ cursor: 'default' }}>
                SAVE
              </button>
            )}
            <button className="ButtonCancelFullWidth" onClick={this.closePopup}>
              CANCEL
            </button>
          </div>
        </div>
      </Popup>
    );

    return (
      <React.Fragment>
        <div>
          <button onClick={this.openPopup} className="GreenButton">
            <img
              alt=""
              src={AddBalance}
              onClick={this.openPopup}
              style={{ width: '20px', height: '20px', marginLeft: 3 }}
            />
          </button>
        </div>
        {popper}
      </React.Fragment>
    );
  }
}

export default CallBalance;
