import { connect } from "react-redux";
import { commonStyle } from "../../../../assets/css/css";
import { HiRefresh } from "react-icons/hi";
import Topbar from "../../../../common/component/topbar/Topbar";
import Sidebar from "../../../../common/componentV2/sidebar/Sidebar";
import PreLoader from "../../../../common/component/preLoader/PreLoader";

const moment = require('moment-timezone');

function HistoricalStats(props) {

  return (<div className={`${props.className}`}>
    <Topbar />
    <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
      <Sidebar />
      <div className="PageContainer">
        HistoricalStats
      </div>
    </div>
  </div>);
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {})(HistoricalStats));