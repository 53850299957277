import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import activeStatus from '../../../../assets/images/activeStatus.svg';
import tea from '../../../../assets/images/tea.svg';
import lunch from '../../../../assets/images/lunch.svg';
import meeting from '../../../../assets/images/meeting.svg';
import training from '../../../../assets/images/training.svg';
import biobreak from '../../../../assets/images/biobreak.svg';
import offline from '../../../../assets/images/offline.svg';
import away from '../../../../assets/images/away.svg';
import busy from '../../../../assets/images/busy.svg';
const moment = require('moment-timezone');

/* AGENT ACTIVE STATUS */
const optionsAgentActiveStatus = {
  online: { label: 'Active', image: activeStatus, color: '#4daa3e', priority: 1 },
  tea: { label: 'Tea', image: tea, color: '#196cca', priority: 3 },
  lunch: { label: 'Lunch', image: lunch, color: '#196cca', priority: 3 },
  meeting: { label: 'Meeting', image: meeting, color: '#196cca', priority: 3 },
  training: { label: 'Training', image: training, color: '#196cca', priority: 3 },
  biobreak: { label: 'Biobreak', image: biobreak, color: '#196cca', priority: 3 },
  break: { label: 'Break', image: away, color: '#f8bb44', priority: 3 },
  offline: { label: 'Offline', image: offline, color: '#939598', priority: 4 },
  'Not Logged In': { label: 'Offline', image: offline, color: '#939598', priority: 5 },
  busy: { label: 'Busy', image: busy, color: '#F5221B', priority: 2 },
  calling: { label: 'Dailing', image: activeStatus, color: '#4daa3e', priority: 2 },
};

function AgentStatusTable(props) {
  const [agentsData, setAgentsData] = useState([]);
  useEffect(() => {
    props?.data?.sort((a, b) => {
      return (optionsAgentActiveStatus[a.status]?.priority || 0) - (optionsAgentActiveStatus[b.status]?.priority || 0);
    });
    setAgentsData(props?.data || []);
  }, [props?.data]);

  const getStatusIcon = (agent) => {
    return (<div
      className="AgentStatusIcon"
      style={{
        fontSize: '14px',
        color: optionsAgentActiveStatus[agent.status]?.color || "red",
      }}
    >
      {agent?.status !== "Not Logged In" && (
        <>
          <img
            src={optionsAgentActiveStatus[agent.status]?.image}
            style={{
              fill: optionsAgentActiveStatus[agent.status]?.color,
              width: "10px",
              height: "10px"
            }}
            alt=".."
          />
          &nbsp;&nbsp;
        </>
      )}
      {agent?.status?.toUpperCase() || "ERROR"}
    </div>)
  }

  return (
    <div>
      <TableContainer style={{ maxHeight: "430px", overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className='AgentStatusTableHeader' style={{ width: "55%" }}>Agent name</TableCell>
              <TableCell className='AgentStatusTableHeader' style={{ width: "20%" }}>Status</TableCell>
              <TableCell className='AgentStatusTableHeader' align='right' style={{ width: "25%" }}>Last Updated At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='AgentStatusTableBody'>
            {agentsData.map((i, index) => (
              <TableRow key={i._id} style={index % 2 === 1 ? {
                background: 'rgba(175, 82, 222, 0.05)'
              } : {}}>
                <TableCell className='AgentStatusTableContent' style={{ width: "55%" }}>{i.name}</TableCell>
                <TableCell className='AgentStatusTableContent' style={{ width: "20%" }}>
                  {getStatusIcon(i)}
                </TableCell>
                <TableCell className='AgentStatusTableContent' align='right' style={{ width: "25%" }}>
                  {i.lastUpdatedAt
                    ? moment(i.lastUpdatedAt).tz("Asia/Kolkata").format("YYYY-MM-DD, hh:mm A")
                    : "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default AgentStatusTable