import React, { useEffect, useState } from 'react';
import Popup from '../../../common/component/popups/popups';
import Close from '../../../assets/images/popupClose.png';
import { POST, GET } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
import Lottie from 'react-lottie';
import successIcon from '../../../assets/json/success.json';
import { ADMIN_ADD_CALL_BALANCE_BUTTON } from '../../../common/posthog/events';
import { logPostHogEvent } from '../../../common/posthog/posthog';
import PreLoader from '../preLoader/PreLoader';
const subUrl = process.env.REACT_APP_SUBSCRIPTION_API;
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;
const vmnUrl = process.env.REACT_APP_VMN_API;
const pbxDomain = process.env.REACT_APP_PBX_DOMAIN;

const defaultLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: successIcon,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function AddOns(props) {
  const [credits, setCredits] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [DIDaddOn, setDIDaddOn] = useState(null);
  const [didErr, setDidErr] = useState('');
  const [callBalanceList, SetCallBalanceList] = useState([]);
  const [selectedCallBalance, setSelectedCallBalance] = useState(null);
  const [agentInfo, setAgentInfo] = useState(1);

  const fetchAddOnPrice = async () => {
    console.log('props addons', props);
    setProcessing(true);
    await GET(`${orgUrl}v1/organisation/addons`, { product: props.product })
      .then((res) => {
        setProcessing(false);
        const did = res?.data?.response.find(
          (obj) => obj.addonType.toUpperCase() === 'DID'
        );
        setDIDaddOn(did);

        const callBalance = res?.data?.response.filter(
          (obj) => obj.addonType.toUpperCase() === 'CREDITS'
        );
        SetCallBalanceList(callBalance);

        const agents = res?.data?.response?.find(
          (obj) => obj.addonType.toUpperCase() === 'AGENT'
        );
        setAgentInfo(agents);
      })
      .catch((err) => {
        setProcessing(false);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });
  };

  useEffect(() => {
    fetchAddOnPrice();
  }, []);

  // const openPopup = () => {
  //   logPostHogEvent(ADMIN_ADD_CALL_BALANCE_BUTTON);
  // };

  const closePopup = () => {
    props.closePopup();
    setCredits(1);
    setProcessing(false);
  };

  const calculateCreditCharges = (credits) => {
    if (props.type === 'DID') {
      return credits * DIDaddOn?.price;
    }
    if (props.type === 'MINUTE') {
      return selectedCallBalance?.price || 0;
    }
    if (props.type === 'AGENT') {
      return credits * agentInfo?.price;
    }
    return 0;
  };

  const calculateGSTCharges = () => {
    const initialAmount = calculateCreditCharges();
    let GST = initialAmount * 0.18;
    return GST.toFixed(2) || 0;
  };

  const calculateTotalCharges = () => {
    const initialAmount = calculateCreditCharges();
    let total = initialAmount * 1.18;
    return total.toFixed(2) || 0;
  };

  const ProceedWithCheckout = async () => {
    const data = {};
    setDidErr('');
    if (props.type === 'DID') {
      if (credits === 0) return toast.error('Minimum 1 quantity required');
      data.quantity = credits;
      data.addonCode = DIDaddOn.addonCode;
      const availableDids = await GET(
        `${vmnUrl}did/get-available-dids?domain=${pbxDomain}`
      );
      const availableDidsCount =
        availableDids?.data?.response?.availableDidsCount;
      if (availableDidsCount <= 0 || availableDidsCount < credits) {
        setDidErr(
          'Requested virtual numbers are out of stock. Please check back in sometime.'
        );
        return false;
      }
    }
    if (props.type === 'MINUTE') {
      data.quantity = 1;
      data.addonCode = selectedCallBalance?.addonCode;
    }

    if (props.type === 'AGENT') {
      data.quantity = credits;
      data.addonCode = agentInfo?.addonCode;
    }

    setProcessing(true);
    await GET(`${subUrl}v1/payment-link/add-on/update`, data)
      .then((res) => {
        if (props.type === 'DID') {
          localStorage.setItem('add-on-purchase', DIDaddOn?.addonType);
        }
        if (props.type === 'MINUTE') {
          localStorage.setItem(
            'add-on-purchase',
            selectedCallBalance?.addonType
          );
        }
        if (props.type === 'AGENT') {
          localStorage.setItem('add-on-purchase', agentInfo?.addonType);
        }
        window.location.href = res.data.response.url;
        setProcessing(false);
      })
      .catch((err) => {
        setProcessing(false);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });
    setDidErr('');
  };

  // useEffect(() => {
  //   console.log('000', selectedCallBalance);
  // }, [selectedCallBalance]);

  return (
    <Popup open={true} closeOnDocumentClick={false} closeOnEscape={false}>
      {processing && <PreLoader />}
      <div className="PopupContainer">
        <div className="PopupHeaderContainer">
          <p>
            ADD {props.type === 'DID' && ' Virtual Number'}
            {props.type === 'MINUTE' && ' Credits'}
            {props.type === 'AGENT' && ' Agent'}
          </p>
          <img className="PopupClose" src={Close} onClick={closePopup} />
        </div>
        {/* {success ? (
          <div className="PopupBody">
            <Lottie options={defaultLottieOptions} height={400} width={400} />
            <p
              style={{
                fontSize: '16px',
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#07aa3f',
                letterSpacing: '-0.48px',
              }}
            >
              Payment Successful!
            </p>
          </div>
        ) : ( */}
        <div className="PopupBody">
          {props.type === 'DID' && (
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">
                How many Virtual Numbers
              </label>
              <div class="DropdownWrapper">
                <select
                  class="PopupDropDown"
                  value={credits}
                  onChange={(e) => {
                    setCredits(e.target.value);
                  }}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                </select>
              </div>
            </div>
          )}
          {props.type === 'AGENT' && (
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">How many Agents</label>
              <div class="DropdownWrapper">
                <select
                  class="PopupDropDown"
                  value={credits}
                  onChange={(e) => {
                    setCredits(e.target.value);
                  }}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                </select>
              </div>
            </div>
          )}
          {props.type === 'MINUTE' && (
            <div className="PopupInputContainer">
              <label className="PopupInputLabel">Select Credits</label>
              <div class="DropdownWrapper">
                <select
                  class="PopupDropDown"
                  value={
                    selectedCallBalance
                      ? JSON.stringify(selectedCallBalance)
                      : ''
                  } // Convert object to string for the value
                  onChange={(e) => {
                    setSelectedCallBalance(JSON.parse(e.target.value)); // Parse string back to object on change
                  }}
                >
                  <option value="" disabled>
                    Select Credits Balance
                  </option>
                  {callBalanceList.map((item) => (
                    <option value={JSON.stringify(item)}>{item.name}</option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {props.type === 'DID' && (
            <p
              style={{
                color: '#00000099',
                textAlign: 'center',
                fontSize: '12px',
                fontWeight: 500,
                margin: '16px 0 0',
              }}
            >
              1 Virtual Number = ₹{calculateCreditCharges(1)}/month, ₹
              {calculateCreditCharges(1) * 12}/year
            </p>
          )}
          {props.type === 'MINUTE' && (
            <>
              {/* <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '368px',
                  margin: 'auto',
                  marginTop: '24px',
                }}
              >
                <p>Sub Total</p>
                <p>₹{calculateCreditCharges()}</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '368px',
                  margin: 'auto',
                }}
              >
                 <p>GST 18%</p>
                <p>₹{calculateGSTCharges() || 0}</p> 
              </div>*/}
              <hr style={{ width: '368px' }} />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '368px',
                  margin: 'auto',
                }}
              >
                <p style={{ marginBottom: 0 }}>
                  <strong>Total</strong>
                </p>
                <p style={{ marginBottom: 0 }}>
                  <strong>₹{calculateTotalCharges() || 0}</strong>
                </p>
              </div>
              <hr style={{ width: '368px' }} />
            </>
          )}
          <button
            className="ButtonFullWidth"
            disabled={processing}
            onClick={ProceedWithCheckout}
          >
            CHECKOUT
          </button>

          <p
            style={{
              color: '#00000099',
              textAlign: 'center',
              fontSize: '12px',
              fontWeight: 500,
              margin: '16px 0 0',
            }}
          >
            {props.type === 'DID' && 'Amount pro-rated to plan expiry'}
            {props.type === 'MINUTE' && 'Minutes valid until plan expiry'}
          </p>
          <p
            style={{
              color: '#F8153B',
              textAlign: 'center',
              fontSize: '13px',
              fontWeight: 500,
              paddingRight: '15px',
              paddingLeft: '15px',
              paddingTop: '15px',
            }}
          >
            {' '}
            {didErr}
          </p>
        </div>
        {/* )} */}
      </div>
    </Popup>
  );
}
