import { useRef, useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import moment, { duration } from 'moment';
import { Container, Row, Col, Button, Card, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { FaPlay, FaPause, FaStepForward, FaStepBackward, FaPlayCircle, FaPauseCircle } from 'react-icons/fa';
import './index.css';
import ai from "../../../assets/images/ai.svg";
import aiIcon from "../../../assets/images/AiIcon.svg";
import phoneMissed from "../../../assets/images/phone_missed.svg";
import positiveLight from "../../../assets/images/positive-light.svg";
import positiveGrey from "../../../assets/images/positive-grey.svg";
import neutralLight from "../../../assets/images/neutral-light.svg";
import neutralGrey from "../../../assets/images/neutral-grey.svg";
import negativeLight from "../../../assets/images/negative-light.svg";
import negativeGrey from "../../../assets/images/negative-grey.svg";
import downloadRecording from "../../../assets/images/downloadRecording.svg";
import callImg from "../../../assets/images/call.svg";
import closeIcon from "../../../assets/images/close_small.svg";
import activeAiIcon from "../../../assets/images/activeAi.svg";
import InactiveAiIcon from "../../../assets/images/InactiveAi.svg";
import animationData from '../../../assets/json/ailoader.json';
import { GET } from '../../../services/HttpRequests';
import { toast } from 'react-toastify';
const ccUrl = process.env.REACT_APP_CLOUD_CALL_CENTER;
const analyticsUrl = process.env.REACT_APP_ANALYTICS_API;
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const AudioPlayer = ({ audioSrc }) => {
  const audioRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };
  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  const updateCurrentTime = (element) => {
    const newTime = parseFloat(element.target.value);
    audioRef.current.currentTime = newTime;
    setCurrentTime(parseInt(newTime));
  };
  useEffect(() => {
    const slider = document.getElementById('myinput');
    if (slider) updateBackground(slider);
  }, [currentTime, duration]);
  const updateBackground = (element) => {
    let percentage = ((element.value - 0) / (duration || 1)) * 100;
    if (duration === 0) {
      percentage = 100;
    }
    element.style.background = `linear-gradient(to right, #7190FF 0%, #7190FF ${percentage}%, #DEE2E6 ${percentage}%, #DEE2E6 100%)`;
  };
  const handleTimeUpdate = () => {
    setCurrentTime(parseInt(audioRef.current.currentTime));
    if (parseInt(audioRef.current.currentTime) === duration) {
      setIsPlaying(false);
    }
  };
  const handleLoadedMetadata = () => {
    setDuration(parseInt(audioRef.current.duration) || 0);
  };
  return (
    <div style={{
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '400px',
      margin: 'auto',
    }}>
      <audio
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
      >
        <source src={audioSrc} type="audio/wav" />
      </audio>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div onClick={togglePlayPause} style={{ margin: '0 10px', cursor: 'pointer' }}>
          {isPlaying ? <FaPauseCircle size={33} color='#7190FF' /> : <FaPlayCircle size={33} color='#7190FF' />}
        </div>
        <div style={{ width: '45px' }}>{formatTime(currentTime)}</div>
        <input
          type="range"
          min="0"
          max={duration}
          value={currentTime}
          id="myinput"
          onChange={updateCurrentTime}
        />
        <span>{formatTime(duration)}</span>
        &nbsp;
        <div style={{ margin: '0px 10px 2px 10px' }}>
          <a href={audioSrc} download>
            <img src={downloadRecording} width={'20px'} height={'20px'} />
          </a>
        </div>
      </div>
    </div>
  );
};
const SentimentCard = ({ analysis, img, stylingClass }) => {
  return (
    <Card style={{ border: 'none' }}>
      <Card.Img variant="top" src={img} height={'60px'} width={'60px'} />
      <Card.Body className={stylingClass}>
        <Card.Text>
          {analysis}
        </Card.Text>
      </Card.Body>
    </Card>
  )
}
function AICallAnalysisPopup(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [isError, setIsError] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [summary, setSummary] = useState('');
  const [sentiment, setSentiment] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    fetchAiDataHandler();
  }
  const formatDuration = (dur) => {
    if (!dur) {
      return "-";
    }
    const min = moment.utc(dur * 1000).format('mm');
    const sec = moment.utc(dur * 1000).format('ss');
    return `${min}m ${sec}s`;
  }
  const formatTime = (time) => {
    if (!time) {
      return "-";
    }
    console.log(time);
    const formattedTime = moment(time).format('HH:mm a');
    return formattedTime;
  }
  const formatDate = (date) => {
    if (!date) {
      return "-";
    }
    const formattedDate = moment(date).format('DD-MM-YYYY');
    return formattedDate;
  }
  const fetchAiDataHandler = async () => {
    setIsLoading(true);
    const organisationId = localStorage.getItem('doosra-biz-organisation-id');
    await GET(`${analyticsUrl}v1/report/get-call-analysis`, { callId: props.callId, organisationId })
      .then((res) => {
        setIsLoading(false);
        setIsError(false);
        const data = res.data.response;
        setSentiment(data.sentiment?.toLowerCase());
        setSummary(data.summary);
        setKeywords(data.keywords);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
      });
  }
  return (
    <>
      {/* <Button style={{ backgroundColor: 'white', border: '1px solid purple', height: '40px', width: '40px' }} onClick={handleShow}>
        <img src={ai} style={{ marginRight: '5px', height: '20px', width: '20px' }} />
      </Button> */}
      {/* <Tooltip id={`tooltip-left`}>
        Tooltip on <strong>left</strong>.
      </Tooltip> */}
      {props?.duration > 10 ?
        <img onClick={handleShow} src={activeAiIcon} style={{
          height: '33px',
          width: '33px',
          cursor: 'pointer'
        }} /> :
        <OverlayTrigger
          placement="left"
          delay={{ show: 250, hide: 400 }}
          overlay={
            <Tooltip id={`tooltip-left`}>
              Call duration too short for analysis
            </Tooltip>
          }
        >
          <img src={InactiveAiIcon} style={{
            height: '33px',
            width: '33px',
            cursor: 'pointer'
          }} />
        </OverlayTrigger>
      }
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        keyboard={false}
        className='custom-modal-width'
      >
        <div style={{
          borderRadius: '50%',
          overflow: 'hidden',
          position: 'absolute',
          top: '-15px',
          right: '-15px',
          width: '30px',
          height: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'red',
          boxShadow: '0 3px 6px rgba(0, 0, 0, 0.3), 0 3px 6px rgba(0, 0, 0, 0.23)'
        }}>
          <img
            onClick={handleClose}
            src={closeIcon}
            style={{
              cursor: 'pointer',
              zIndex: '10',
              width: '30px',
              height: '30px'
            }}
            alt="Close"
          />
        </div>
        {!isLoading ? <Container fluid className="mainContainer p-4 bg-light rounded">
          <Row>
            <Col>
              <h2 className="heading">
                AI call analysis <img src={ai} />
              </h2>
            </Col>
          </Row>
          {/* Contact Details */}
          <Row className="mb-3">
            <Col>
              <Card className="shadow-sm">
                <Card.Body>
                  <Row className="align-items-center">
                    <Col xs={1}>
                      <div className="d-flex justify-content-center align-items-center rounded-circle" style={{ width: '40px', height: '40px', backgroundColor: '#07AA3F1A' }}>
                        <img src={callImg} color="red" width={'18px'} height={'18px'} />
                      </div>
                    </Col>
                    <Col xs={7}>
                      <div style={{ marginLeft: '10px' }}>
                        <div className="contactName mb-1">{props?.name || 'Unknown contact'}</div>
                        <div className="contactNumber text-muted">{props?.number || '-'}</div>
                      </div>
                    </Col>
                    <Col xs={4} className="text-end">
                      <div>
                        <div className="callTime mb-1">
                          {props.duration ? formatDuration(props.duration) : '-'}, {props.time ? formatTime(props.time) : '-'}
                        </div>
                        <div className="callDate">{props.date ? formatDate(props.date) : '-'}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs={1} />
                    <Col xs={9}>
                      <div style={{ marginLeft: '10px' }} >
                        <div className='callDescription mb-1'>Call {props?.outgoing ? "made" : "received"} by <span style={{ textDecoration: "underline", color: '#007bff', cursor: 'default' }}>{props?.agentName || "-"}</span></div>
                        <div className='dispositionHeading'>Disposition : <Badge className='disposition' pill>{props?.disposition || "No Disposition"}</Badge></div>
                      </div>
                    </Col>
                    <Col xs={2} />
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {!isError ? (<>
            {/* Sentiment Analysis  */}
            < Row className="mb-3 text-center">
              <Col>
                <Card>
                  <div className='m-4'>
                    <div className='sentimentHeading'>Sentiment analysis</div>
                    <div className="d-flex justify-content-around mt-4">
                      <SentimentCard
                        analysis="Positive"
                        img={sentiment === 'positive' ? positiveLight : positiveGrey}
                        stylingClass={sentiment === 'positive' ? "positiveSentiment" : "greyOutSentiment"}
                      />
                      <SentimentCard
                        analysis="Neutral"
                        img={sentiment === 'neutral' ? neutralLight : neutralGrey}
                        stylingClass={sentiment === 'neutral' ? "neutralSentiment" : "greyOutSentiment"}
                      />
                      <SentimentCard
                        analysis="Negative"
                        img={sentiment === 'negative' ? negativeLight : negativeGrey}
                        stylingClass={sentiment === 'negative' ? "negativeSentiment" : "greyOutSentiment"}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            {/* Keywords  */}
            <Row className="mb-3">
              <Col xs={6} className="d-flex">
                <Card className="flex-grow-1 d-flex flex-column" style={{ marginRight: '-10px' }}>
                  <div className="m-4 flex-grow-1">
                    <div className="keywordsHeading">Keywords</div>
                    <div className=" d-flex flex-wrap mt-3">
                      {keywords.map((keyword) =>
                        <Badge pill className="keyword m-1">
                          {
                            keyword[0].toUpperCase() + keyword.substring(1)
                          }
                        </Badge>)}
                    </div>
                  </div>
                </Card>
              </Col>
              <Col xs={6} className="d-flex">
                <Card className="flex-grow-1 d-flex flex-column" style={{ marginLeft: '-10px' }}>
                  <div className="m-4 flex-grow-1">
                    <div className="summaryHeading">AI call summary</div>
                    <p className="summary mt-2 mb-0" style={{ textAlign: 'justify' }}>
                      {summary}
                    </p>
                  </div>
                </Card>
              </Col>
            </Row></>
          ) : (
            <Row className='mb-3'>
              <Col>
                <Card className="shadow-sm">
                  <div className='m-4' style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 20,
                    alignItems: 'center'
                  }}>
                    <div>
                      <img src={negativeLight} width={'45px'} height={'45px'} />
                    </div>
                    <div style={{ color: 'brown', fontSize: '16px', fontWeight: 600 }}>
                      AI analysis not available
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          )}
          {/* Music Player  */}
          <Row className='mb-4'>
            <Col>
              <Card className='mb-1'>
                <div className='m-4'>
                  <AudioPlayer audioSrc={props.audio} />
                </div>
                {/* <Card className="p-3 bg-dark rounded border-0">
                  <audio controls className=" w-100 audio-control-custom">
                    <source src={audioUrl} type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                </Card> */}
              </Card>
            </Col>
          </Row>
        </Container > :
          <Container fluid className="mainContainer p-4 rounded" style={{
            backgroundColor: '#FCFCFF'
          }}>
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: '100%',
              flexDirection: 'column',
              position: 'relative'
            }}>
              <div className='lottieGif'>
                <Lottie
                  options={defaultOptions}
                  height={300}
                  width={400}
                />
              </div>
              <div className='loaderText'>
                AI is analysing your call ...
              </div>
            </div>
          </Container>
        }
      </Modal >
    </>
  );
}
export default AICallAnalysisPopup;