import { connect } from "react-redux";
import { commonStyle } from "../../../../assets/css/css";
import { useEffect, useState } from "react";
import '../styles/LiveStats.css';
import { HiRefresh } from "react-icons/hi";
import { toast } from "react-toastify";
import Topbar from "../../../../common/component/topbar/Topbar";
import Sidebar from "../../../../common/componentV2/sidebar/Sidebar";
import PreLoader from "../../../../common/component/preLoader/PreLoader";
import { GET } from "../../../../services/HttpRequests";
import DataCard from "../component/DataCard";
import LiveCallsTable from "../component/LiveCallsTable";
import AgentStatusTable from "../component/AgentStatusTable";

const moment = require('moment-timezone');
const analyticsUrl = process.env.REACT_APP_ANALYTICS_API;

function LiveMetricsFilter(props) {
  const [searchValue, setSearchValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [btnOnReset, setBtnOnReset] = useState(false);

  useEffect(() => {
    setSearchKey(props?.searchKey);
    setSearchValue(props?.searchValue);
    setBtnOnReset(props?.btnOnReset);
  }, []);

  const onSearch = () => {
    if (btnOnReset) {
      props.onSearch({});
    } else {
      if (searchKey && searchValue) {
        props.onSearch({ searchKey, searchValue });
      }
    }
  }

  return (
    <div className='LiveMetricsFilter'>
      <div className='LiveFilterHeader'>FILTER</div>
      <div>
        <select
          className='LiveDateFilter'
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
        >
          <option value="">Select Filter</option>
          <option value="AGENTNAME">Agent Name</option>
          <option value="AGENTNUMBER">Agent Number</option>
          <option value="CUSTOMERNAME">Customer Name</option>
          <option value="CUSTOMERNUMBER">Customer Number</option>
        </select>
      </div>
      <div>
        <input
          type="text"
          className='LiveDateFilter'
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder={"Enter Value"}
        />
      </div>
      <div>
        <button
          className="LiveFilterSearchButton"
          onClick={onSearch}
        >
          {btnOnReset ? "CLEAR" : "SEARCH"}
        </button>
      </div>
    </div>
  )
}

function LiveStats(props) {

  const [isLoading, setIsLoading] = useState(false);
  const [liveCallsData, setLiveCallsData] = useState([]);
  const [agentsData, setAgentsData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("live-calls");
  const [callType, setCallType] = useState("OUTGOING");
  const currentTime = moment().tz('Asia/Kolkata');
  const [pageRefreshDate, setPageRefreshDate] = useState(currentTime);
  const [searchValue, setSearchValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [btnOnReset, setBtnOnReset] = useState(false);

  const [cardData, setCardData] = useState({
    incomingCallsCount: 0,
    outgoingCallsCount: 0,
    totalCount: 0
  });

  const tabs = [
    {
      label: 'Live Calls',
      link: 'live-calls',
    },
    {
      label: 'Agent Status',
      link: 'agent-status',
    }
  ];

  const TabFilter = (props) => {
    return <div className='LiveStatsTabFilter'>
      {props?.tabs?.map(i =>
        <div
          className={i.link === props?.selectedTab ? "LiveStatsTabFilterActive" : "LiveStatsTabFilterInActive"}
          onClick={() => onChangeTab(i.link)}
        >{i.label}</div>
      )}
    </div>
  }

  const onChangeTab = (tab) => {
    setSelectedTab(tab);
  }

  const onChangeCallType = (e) => {
    setCallType(e.target.value);
    fetchLiveCalls({ callType: e.target.value });
  }

  const onClickRefreshButton = () => {
    if (selectedTab === "live-calls") {
      fetchLiveCalls();
    }
    if (selectedTab === "agent-status") {
      fetchAgentStatus();
    }
  }

  const fetchLiveCalls = async (filter) => {
    try {
      const data = {
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        callType,
        pageNo: 0,
        pageSize: 15
      }
      if (filter?.pageNo) {
        data.pageNo = filter.pageNo - 1;
        data.pageSize = 15;
      }
      if (filter?.callType) {
        data.callType = filter.callType;
      }
      if (filter?.searchKey && filter?.searchValue) {
        data.searchType = filter.searchKey;
        data.searchValue = filter.searchValue;
      }
      setIsLoading(true);
      const response = await GET(analyticsUrl + 'v1/analytics/live-calls', data);
      setLiveCallsData(response?.data?.response || {});
      setCardData(response?.data?.response?.liveCallsCount);
      setSearchKey(response?.data?.response?.searchType || "");
      setSearchValue(response?.data?.response?.searchValue || "");
      if (data?.searchType && data?.searchValue) {
        setBtnOnReset(true);
      } else {
        setBtnOnReset(false);
      }

      setPageRefreshDate(moment().tz('Asia/Kolkata'));
      setIsLoading(false);
    } catch (e) {
      toast.error("Unable to fetch currently!!! Please try after some time.");
      setIsLoading(false);
    }
  }

  const fetchAgentStatus = async (filter = {}) => {
    setIsLoading(true);
    let data = { ...filter };
    console.log(JSON.stringify(data));
    data.organisationId = localStorage.getItem('doosra-biz-organisation-id');
    await GET(analyticsUrl + 'v1/analytics/live-agent-status', data)
      .then((res) => {
        let agentsMetrics = res?.data?.response?.liveAgentStatus;
        setAgentsData(agentsMetrics || []);
        setCardData(res?.data?.response?.liveCallsCount);
        setPageRefreshDate(moment().tz('Asia/Kolkata'));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (selectedTab === "live-calls") {
      fetchLiveCalls();
    }
    if (selectedTab === "agent-status") {
      fetchAgentStatus();
    }
  }, [selectedTab]);

  return (<div className={`${props.className}`}>
    <Topbar />
    <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
      <Sidebar />
      <div className="PageContainer">
        {isLoading && <PreLoader />}
        {!isLoading && (
          <div className='LiveStats'>
            <div className='LiveStatsHeader'>
              <div className='LiveStatsRefreshComponent'>
                <div className='LiveStatsName'>Live stats</div>
                <div className='LiveStatsDescription'>Description</div>
              </div>
              <div className='LiveStatsRefreshComponent'>
                <div className='LiveStatsRefreshOne' onClick={onClickRefreshButton}><HiRefresh /> Refresh</div>
                <div className='LiveStatsRefreshTwo'>Last Updated at {moment(pageRefreshDate).tz('Asia/Kolkata').format("YYYY-MM-DD, hh:mm A")}</div>
              </div>
            </div>
            <div className="AgentStatusDataCardsContainer">
              <DataCard
                title="Total Calls"
                count={cardData?.totalCount}
              />
              <DataCard
                title="Incoming Calls"
                count={cardData?.incomingCallsCount}
              />
              <DataCard
                title="Outgoing Calls"
                count={cardData?.outgoingCallsCount}
              />
            </div>

            {/* Inner Tab Area - Start */}
            <div className='LiveStatsContainer'>
              <div className='LiveStatsHeaderName'>
                <TabFilter
                  tabs={tabs}
                  selectedTab={selectedTab}
                />
                {selectedTab === 'live-calls' &&
                  <>
                    <div>
                      <select
                        className='LiveDateFilter'
                        value={callType}
                        onChange={onChangeCallType}
                      >
                        <option value="OUTGOING">Outgoing</option>
                        <option value="INCOMING">Incoming</option>
                      </select>
                    </div>
                    <LiveMetricsFilter
                      searchKey={searchKey}
                      searchValue={searchValue}
                      onSearch={fetchLiveCalls}
                      btnOnReset={btnOnReset}
                    />
                  </>
                }
              </div>
              <div className='AgentStatusTable'>
                {selectedTab === 'live-calls' &&
                  <LiveCallsTable
                    data={liveCallsData}
                    fetchData={fetchLiveCalls}
                    callType={callType}
                    totalPages={callType === "INCOMING" ? cardData.incomingCallsCount : cardData.outgoingCallsCount}
                  />}
                {selectedTab === 'agent-status' &&
                  <AgentStatusTable
                    data={agentsData || []}
                    fetchData={fetchAgentStatus}
                  />}
              </div>
            </div>
            {/* Inner Tab Area - End  */}
          </div>
        )}
      </div>
    </div>
  </div>);
}

const mapStateToProps = (state) => ({
  sidebarClosed: state.sidebar.sidebarClosed,
  userDetails: state.recovery.userDetails,
});

export default commonStyle(connect(mapStateToProps, {})(LiveStats));