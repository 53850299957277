import React, { useState, useRef } from "react";
import Sidebar from '../../../common/componentV2/sidebar/Sidebar';
import Topbar from '../../../common/component/topbar/Topbar';
import TabSearchBar from '../../../common/component/TabSearchbar/TabSearchBarComponent';
import { connect } from 'react-redux';
import { commonStyle } from '../../../assets/css/css';
import { TableContainer } from '@material-ui/core';
import { toast } from 'react-toastify';
import { GET } from '../../../services/HttpRequests';
import moment from 'moment';
import Paginator from '../../../common/component/pagination/PaginationComponent';
import PreLoader from '../../../common/component/preLoader/PreLoader';
import CsvDownloader from 'react-csv-downloader';
const planAvailable = localStorage.getItem('is_plan_available');
const orgUrl = process.env.REACT_APP_ORGANIZATION_API;

const CreditConsumption = (props) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(15);
    const [fromDate, setFromDate] = useState();
    const [endDate, setEndDate] = useState();
    const [exportDocs, setExportDocs] = useState([]);
    const csvLink = useRef(null);


    let columns = [
        { id: 'Date', displayName: 'Date' },
        { id: 'Calls_Credits_Consumed', displayName: 'Calls Credits Consumed' },
        { id: 'VB_Credits_Consumed', displayName: 'VB Credits Consumed' },
        { id: 'AI_Credits_Consumed', displayName: 'AI Credits Consumed' },
        { id: 'Total_Credits_Consumed', displayName: 'Total Credits Consumed' },
    ];

    const fetchData = (pageNo = 1) => {
        const data = {
            organisationId: localStorage.getItem('doosra-biz-organisation-id'),
            pageNo: pageNo,
            pageSize: pageSize,
            startDate: fromDate,
            endDate: endDate
        };
        setIsLoading(true);
        GET(`${orgUrl}v1/organisation/credit-consumption`, data)
            .then((res) => {
                setData(res?.data?.response);
                setCurrentPage(Number(res?.data?.response?.payload?.pageNo || 0));
                setTotalCount(Number(res?.data?.response?.payload?.totalCount || 0));
                setIsLoading(false);
            })
            .catch((err) => {
                toast.error(err);
                setIsLoading(false);
            });
    };

    useState(() => {
        const startingDate = moment().subtract(6, "days").format("YYYY-MM-DD");
        const endingDate = moment().format("YYYY-MM-DD");
        setFromDate(startingDate);
        setEndDate(endingDate);
        fetchData();
    }, []);

    const fetchCreditConsumption = (value) => {
        fetchData(value)
    }

    const handleOnSearch = () => {
        fetchData();
    }
    const tabs = [
        {
            title: 'Account',
            active: false,
            subtitle: ``,
            onClick: () => props.history.push('/account-settings'),
        },
        {
            title: 'Credit History',
            active: false,
            subtitle: ``,
            onClick: () => this.props.history.push('/credit-history'),
        },
        {
            title: 'Credit Consumption',
            active: true,
            subtitle: ``,
            onClick: () => props.history.push('/credit-consumption'),
        },
        {
            title: 'Operating Schedule',
            active: false,
            subtitle: ``,
            onClick: () => props.history.push('/off-hours'),
        },
        {
            title: 'Call Settings',
            active: false,
            subtitle: ``,
            onClick: () => props.history.push('/call-settings'),
        },
        {
            title: 'Agent Settings',
            active: false,
            subtitle: ``,
            onClick: () => props.history.push('/agent-settings'),
        },
    ];
    if (planAvailable === true || planAvailable === 'true') {
        tabs.push({
            title: 'KYC',
            active: false,
            subtitle: ``,
            onClick: () => props.history.push('/kyc'),
        });
    }
    const CreditTabs = [
        {
            title: 'Credit Purchase',
            active: false,
            subtitle: ``,
            onClick: () => props.history.push('/credit-history'),
        },
        {
            title: 'Credit Consumption',
            active: true,
            subtitle: ``,
            onClick: () => props.history.push('/credit-consumption'),
        }
    ]
    const handleExport = async () => {
        let pageSize = 5000;
        let pageNumber = 1;
        let docs = [];
        setIsLoading(true);
        try {
            while (true) {
                const res = await GET(`${orgUrl}v1/organisation/credit-consumption`, {
                    organisationId: localStorage.getItem('doosra-biz-organisation-id'),
                    pageNo: pageNumber,
                    pageSize,
                    startDate: fromDate,
                    endDate: endDate,
                })
                const creditConsumptionDocs = res?.data?.response?.data;
                docs = [...docs, ...creditConsumptionDocs];
                if (creditConsumptionDocs.length < pageSize) {
                    break;
                }
                pageNumber++;
            }
        } catch (error) {
            console.log(error);
        };
        setIsLoading(false);
        setExportDocs(docs);
        csvLink.current.handleClick();
    }
    return (
        <div className={`${props.className}`}>
            <Topbar />
            <div style={{ display: 'flex', height: 'calc(100vh - 72px)' }}>
                <Sidebar />
                <div className="ContentContainer">
                    <TabSearchBar type={'creditConsumption'} tabs={CreditTabs}>
                        <div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "2rem" }}>
                                    <label style={{
                                        fontSize: '12px',
                                        fontFamily: 'Montserrat',
                                        color: '#666',
                                        fontWeight: 'bold',
                                        padding: 0,
                                        margin: 0,
                                    }}>Start Date</label>
                                    <input
                                        style={{
                                            border: "1px solid black",
                                            borderRadius: "4px"
                                        }}
                                        type="date"
                                        value={fromDate}
                                        onChange={(e) => setFromDate(e.target.value)}
                                    />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", marginLeft: "2rem" }}>
                                    <label style={{
                                        fontSize: '12px',
                                        fontFamily: 'Montserrat',
                                        color: '#666',
                                        fontWeight: 'bold',
                                        padding: 0,
                                        margin: 0,
                                    }}>End Date</label>
                                    <input
                                        style={{ border: "1px solid black", borderRadius: "4px" }}
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <button
                                        className="SearchButton"
                                        style={{ marginLeft: "2rem", height: "35px", width: "120px", marginBottom: "5px", fontSize: "0.8rem" }}
                                        onClick={handleOnSearch}
                                    >
                                        SEARCH
                                    </button>
                                    <button
                                        className="Otherbutton"
                                        style={{ marginLeft: "2rem", height: "35px", width: "120px", marginBottom: "5px", backgroundColor: "rgb(7, 170, 63)", color: "white", fontSize: "0.8rem" }}
                                        onClick={handleExport}
                                    >
                                        EXPORT
                                    </button>
                                </div>
                            </div>
                        </div>
                    </TabSearchBar>
                    <div className="ContentContainer">
                        {
                            isLoading && <PreLoader></PreLoader>
                        }
                        <div>
                            <TableContainer>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Calls Credits Consumed</th>
                                            <th>VB Credits Consumed</th>
                                            <th>AI Credits Consumed</th>
                                            <th>Total Credits Consumed</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.data?.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {moment(item?.date).format('DD/MM/YY')}
                                                </td>
                                                <td>{item?.callsConsumedCredits}</td>
                                                {/* <td>{item?.isBlocked ? 'yes' : 'no'}</td> */}
                                                <td>{item?.VoiceBroadCastCallsConsumedCredits}</td>
                                                <td>{item?.qualityControlCost}</td>
                                                <td>{item?.totalConsumedCredits}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </TableContainer>
                        </div>
                    </div>
                    <Paginator
                        currentPage={currentPage}
                        lastPage={
                            Math.ceil(totalCount / pageSize)
                        }
                        getInfo={(value) => {
                            fetchCreditConsumption(value);
                        }}
                    />
                </div>
            </div>
                <CsvDownloader
                  filename={`${new Date().toLocaleDateString()}`}
                  separator=","
                  ref={csvLink}
                  columns={columns}
                  datas={
                      exportDocs.length
                          ? exportDocs.map((item) => {
                              return {
                                  Date: moment(item?.date).format('DD/MM/YY'),
                                  Calls_Credits_Consumed: item?.callsConsumedCredits,
                                  VB_Credits_Consumed: item?.VoiceBroadCastCallsConsumedCredits,
                                  AI_Credits_Consumed: item?.qualityControlCost,
                                  Total_Credits_Consumed: item?.totalConsumedCredits,
                              };
                          })
                          : [{}]
                  }
                >
                <span></span>
                </CsvDownloader>
        </div>
    )
}

const mapStateToProps = (state) => ({
    sidebarClosed: state.sidebar.sidebarClosed,
    agentEmailVerification: state.topbar.agentEmailVerification,
});

export default commonStyle(connect(mapStateToProps, {})(CreditConsumption));