import React from 'react';
import '../index.css';
import SentimentPieChart from './SentimentPieChart';
import positiveLight from "../../../assets/images/positive-light.svg";
import { GET } from '../../../services/HttpRequests';
const BIZ_ANALYTICS_URL = process.env.REACT_APP_ANALYTICS_API;

function SentimentCallCard({ header, sentimentType, callsCount, callsPercentage, exportData }) {

  const getTextColor = () => {
    switch (sentimentType) {
      case 'Positive':
        return 'GreenColor';
      case 'Negative':
        return 'RedColor';
      default:
        return 'NeutralColor';
    }
  };
  return (
    <div className='SentimentCallCard'>
      <div className='SentimentCallCardTop'>
        <div className={`SentimentalCallCardHeading ${getTextColor()}`}>{header} </div>
        <div className='SentimentalCallCardData'>
          <div className='SentimentalCallCardDataValue'>{callsCount}</div>
          <div className='SentimentalCallCardDataPercentage'>({callsPercentage}%)</div>
        </div>
      </div>
      {sentimentType &&
        <div
          className='SentimentCallCardBottom'
          onClick={() => exportData(sentimentType)}
        >
          Export calls
        </div>
      }
    </div >)
}

function SentimentScoreComponent(props) {

  const downloadCSV = (data) => {
    const csvHeaders = [
      "CALLID", "AGENTNUMBER",
      "CUSTNUMBER", "DIDNUMBER",
      "DURATION", "CALLTYPE",
      "RECORDINGURL", "SENTIMENT",
      "SENTIMENTSCORE", "SUMMARY",
    ];

    const csvRows = [];
    csvRows.push(csvHeaders.join(","));

    data.forEach((row) => {
      const values = [
        row.callId, row.agentNumber,
        row.custNumber, row.didNumber,
        row.duration, row.callType,
        row.recordingUrl, row.sentiment,
        row.sentimentScore, row.summary,
      ].map(value => `"${String(value).replace(/"/g, '""')}"`);
      csvRows.push(values.join(","));
    });

    const csvContent = csvRows.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "call_data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const downloadCallsData = async (sentimentType) => {
    try {
      const data = {
        reportId: props?.reportId,
        organisationId: localStorage.getItem('doosra-biz-organisation-id'),
        sentimentType
      }
      const responseData = await GET(BIZ_ANALYTICS_URL + 'v1/report/export', data);
      const resultData = responseData?.data?.response?.exportData;
      downloadCSV(resultData);
    } catch (error) {
      console.log('Error in fetchCallsData', error);
    }
  }

  return (
    <div className='SentimentScoreContainer'>
      <div className='SentimentScoreContainerHeader'>
        <div className='SentimentScoreContainerHeading'> Calculate sentiment score</div>
        {/* <div className='SentimentScoreContainerFilterSection'>FILTER</div> */}
      </div>
      <hr />
      <div className='SentimentScoreContainerBody'>
        <div className='SentimentScoreContainerBodyLeft'>
          <div className='SentimentScoreCard'>
            <div className='SentimentScoreCardLeft'>
              <div className='SentimentScoreCardTitle'>
                Sentiment score
              </div>
              <div className='SentimentScoreCardValue'>
                {props?.sentimentScore || 0}/5
              </div>
              <div className='SentimentScoreCardDescription'>
                {props?.totalCalls || 0} calls average analysed
              </div>
            </div>
            <div className='SentimentScoreCardRight'>
              {/* <img src={positiveLight} /> */}
              🙂
            </div>
          </div>
          <div className='SentimentCallsSection'>
            <SentimentCallCard
              header="Number of Positive calls"
              sentimentType="Positive"
              callsCount={props?.positiveCalls || 0}
              callsPercentage={props?.positiveCallsPercentage || 0}
              exportData={downloadCallsData}
            />
            <SentimentCallCard
              header="Number of Negative calls"
              sentimentType="Negative"
              callsCount={props?.negativeCalls || 0}
              callsPercentage={props?.negativeCallsPercentage || 0}
              exportData={downloadCallsData}
            />
          </div>
          <div className='SentimentCallsSection'>
            <SentimentCallCard
              header="Number of Neutral calls"
              sentimentType="Neutral"
              callsCount={props?.neutralCalls || 0}
              callsPercentage={props?.neutralCallsPercentage || 0}
              exportData={downloadCallsData}
            />
            <SentimentCallCard
              header="Number of Unclassified calls"
              callsCount={props?.unClassifiedCalls || 0}
              callsPercentage={props?.unClassifiedCallsPercentage || 0}
            />
          </div>
        </div>
        <div className='SentimentScoreContainerBodyRight'>
          <div className='SentimentPieChart'>
            <SentimentPieChart
              positiveCalls={props?.positiveCalls || 0}
              negativeCalls={props?.negativeCalls || 0}
              neutralCalls={props?.neutralCalls || 0}
              unClassifiedCalls={props?.unClassifiedCalls || 0}
            />
          </div>

        </div>
      </div>
    </div>
  )
}

export default SentimentScoreComponent