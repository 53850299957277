import '../styles/LiveStats.css';

export default function DataCard(props) {
  return (
    <div className="MetricCard">
      <div className="MetricCardHeader">
        <h3>{props.title || '-'}</h3>
        {/* {props?.title === 'Total Calls' && <img src={openInNew} />} */}
      </div>
      <p>{props.count || 0}</p>
    </div>
  );
}
